import React, { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';
import {
  createAO, getAOById, getListNama, getUserList, updateAO,
} from 'client/request/accountOfficer';
import { SnackbarContext } from 'context/snackbar';
import {
  Form, FormAutocompleteControlledV2, FormInput,
} from 'components/react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { IAccountOfficer, ICreateUpdateAccountOfficer } from 'types/IAccountOfficer';
import { handleMultiplePromises } from 'utility/handleMultiplePromises';
import { asyncWrapper } from 'utility/asyncWrapper';

const ACCOUNT_OFFICER_TEXT = {
  title: {ADD: 'Add Account Officer', EDIT: 'Edit Account Officer'},
  saveButton: {ADD: 'Add', EDIT: 'Edit'},
  success: {ADD: 'Success Add Account Officer', EDIT: 'Success Edit Account Officer'},
};

function AddEditAccountOfficer({type}: {type: 'ADD' | 'EDIT'}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<IAccountOfficer>({nama: '', parentId: '', userId: ''});
  const snackbar = useContext(SnackbarContext);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [options, setOptions] = useState({manager: [], user: []});

  const initOptions = async () => {
    setIsLoadingOptions(true);
    const results = await handleMultiplePromises([getListNama(), getUserList()], true);
    if (results instanceof Error) {
      console.error(results);
      snackbar.show(`${results.message} on Dropdown Options`, 'error');
    } else {
      const [listNama, listUser] = results;
      setOptions({manager: listNama.data.payload.bd, user: listUser.data.payload.userList.map((u) => ({name: u.email, value: u.userId}))});
    }
    setIsLoadingOptions(false);
  };

  const initData = async () => {
    setIsLoadingData(true);
    try {
      const res = await getAOById(Number(id));
      setFormValues(res.data.payload.bd);
    } catch (e) {
      console.error(e);
      const { message } = e as Error;
      snackbar.show(message, 'error');
    }
    setIsLoadingData(false);
  };

  const handleSubmit = async (data: IAccountOfficer) => {
    setIsLoadingSubmit(true);
    const dataMapped: ICreateUpdateAccountOfficer = {nama: data.nama, parent_id: data.parentId || null, user_id: data.userId};
    const [res, err] = await asyncWrapper(type === 'ADD' ? createAO(dataMapped) : updateAO(id, dataMapped));
    if (err) snackbar.show(err, 'error');
    if (res) {
      snackbar.show(ACCOUNT_OFFICER_TEXT.success[type], 'success');
      navigate('/account-officer');
    }
    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    initOptions();
    if (type === 'EDIT') { initData(); }
  }, [type]);

  return (
    <div>
      <PageHeader title={ACCOUNT_OFFICER_TEXT.title[type]} backButton="/account-officer" />
      <div className=" max-w-xl rounded-2xl p-2 shadow-md">
        <Form
          id={`form-${type}-ao`}
          initialValues={formValues}
          onSubmit={handleSubmit}
          isLoading={isLoadingData || isLoadingOptions || isLoadingSubmit}
          buttonText={ACCOUNT_OFFICER_TEXT.saveButton[type]}
        >
          <FormInput label="Nama" name="nama" maxLength={100} required />
          <FormAutocompleteControlledV2 label="Manager" name="parentId" options={options.manager} isLoading={isLoadingOptions} />
          <FormAutocompleteControlledV2 label="User" name="userId" options={options.user} isLoading={isLoadingOptions} required />
        </Form>
      </div>
    </div>
  );
}

export default AddEditAccountOfficer;

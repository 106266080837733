import { Checkbox, ThemeProvider, createTheme} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {Controller, useFormContext } from 'react-hook-form';
import { ICheckboxOption } from './CheckboxGroup';

interface CheckboxGroupProps {
  name: string;
  label: string;
  disabled?: boolean,
  required?: boolean,
  readOnly?: boolean,
  childOptions: ICheckboxOption[];
  onChange?: (eventThatChanged: React.ChangeEvent<HTMLInputElement>, childOptions: ICheckboxOption[], parentChecked: boolean) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: '20px',
          boxSizing: 'border-box',
        },
      },
    },
  },
});

function CheckboxGroupController(props: CheckboxGroupProps) {
  const {
    name, label, disabled, required, childOptions: child, onChange: handleChange, size, readOnly,
  } = props;
  const {
    control, setValue, getValues,
  } = useFormContext();

  const [checked, setChecked] = useState<boolean>(false);
  const [childOptions, setChildOptions] = useState<ICheckboxOption[]>([]);

  useEffect(() => {
    if (child.length < 1) return;
    setChildOptions(child);
  }, [child]);

  const childNames = childOptions.map((o) => (o.name));

  const labelDisabledStyle = (isDisabled: boolean) => {
    if (isDisabled) {
      return 'border-gray-200 cursor-not-allowed';
    }

    return 'hover:border-gray-500';
  };
  const checkboxStyle = (isReadOnly: boolean, isDisabled: boolean, isChild: boolean) => {
    const baseStyle = isChild ? {transform: 'scale(0.9)', borderColor: 'gray'} : {
      transform: 'scale(0.9)',
      borderColor: 'gray',
      margin: 0,
      padding: 0,
    };
    if (isReadOnly) {
      return {
        color: '#1f2937',
        ...baseStyle,
      };
    }
    if (isDisabled) {
      return {
        color: 'inherit',
        cursor: 'not-allowed',
        ...baseStyle,
      };
    }
    return {
      color: '#2562eb', ...baseStyle,
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col gap-2">
        <Controller
          name={name}
          control={control}
          defaultValue={childOptions.every((co) => co.checked === true)}
          rules={{
            validate: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              atLeastOneChildChecked: (v) => {
                if (required) {
                  if (childOptions.every((co) => co.checked === false)) {
                    return false;
                  }
                  return true;
                }
                return true;
              },
            },
          }}
          render={({ field: {onChange, onBlur, ref} }) => (
            <div className="flex items-center gap-2">
              <Checkbox
                checked={childOptions.every((co) => co.checked === true)}
                indeterminate={!(childOptions.every((co) => co.checked === true)) && childOptions.some((co) => co.checked === true)}
                onChange={(e) => {
                  setChildOptions((prevState) => {
                    const newState = prevState.map((o) => ({...o, checked: e.target.checked}));
                    return newState;
                  });
                  onChange(e.target.checked);
                  childOptions.forEach((co) => {
                    setValue(co.name, e.target.checked);
                  });
                  setChecked(e.target.checked);
                  handleChange(e, childOptions, e.target.checked);
                }}
                onBlur={onBlur}
                inputRef={ref}
                disabled={disabled || readOnly}
                readOnly={readOnly}
                style={checkboxStyle(readOnly, disabled, false)}
                id={name}
                disableRipple
              />
              <div>
                <label htmlFor={name} className={`text-${size} ${readOnly ? '' : labelDisabledStyle(disabled)}`}>{label}</label>
                {required && !disabled && <span className="text-red-600">*</span>}
              </div>
            </div>
          )}
        />

        <div className="flex flex-col ml-2 gap-2">
          {childOptions.map((co) => (
            <Controller
              key={co.name}
              name={co.name}
              control={control}
              rules={{
                required: co.required ?? false,
              }}
              render={({field: {onChange, onBlur, ref}}) => (
                <div className="flex items-center">
                  <Checkbox
                    checked={co.checked}
                    onChange={(e) => {
                      setChildOptions((prevState) => {
                        const newState = prevState.map((o) => {
                          if (o.name === co.name) { return {...o, checked: e.target.checked}; }
                          return o;
                        });
                        return newState;
                      });
                      onChange(e.target.checked);
                      setValue(name, getValues(childNames).every((childChecked) => childChecked === true));
                      handleChange(e, childOptions, checked);
                    }}
                    onBlur={onBlur}
                    inputRef={ref}
                    disabled={co.readOnly || co.disabled}
                    readOnly={co.readOnly ?? false}
                    style={checkboxStyle(co.readOnly, co.disabled, true)}
                    id={co.name}
                    disableRipple
                  />
                  <div>
                    <label htmlFor={co.name} className={`text-${size} ${co.readOnly ? '' : labelDisabledStyle(co.disabled)}`}>{co.label}</label>
                    {co.required && !co.disabled && <span className="text-red-600">*</span>}
                  </div>
                </div>
              )}
            />
          ))}
        </div>
      </div>

    </ThemeProvider>
  );
}

CheckboxGroupController.defaultProps = {
  disabled: false,
  required: false,
  readOnly: false,
  onChange: () => null,
  size: 'xs',
};

export default CheckboxGroupController;

export enum FORM {
  USER = 1,
  EMAIL_TEMPLATE = 9,
  BUYER_PROFILE = 12,
  BUSINESS_DEVELOPMENT = 14,
  LOAN_DOC = 16,
  LENDER = 106,
  BORROWER = 107,
  AUTOLENDING = 108,
  AUTOLENDING_LIST = 110,
  DAFTAR_AKUN_BANK = 111,
  LOAN = 121,
  CART = 125,
  INVOICE = 126,
  RDL = 127,
  DEBET_RDL = 128,
  PROMOSI = 131,
  DANA_MENGENDAP = 138,
  BORROWER_GROUP = 400,
  OFFERING_ROUND = 401,
  BD_LIST_PROJECT = 402,
  AO_LOAN = 403,
  DISBURSEMENT_ORDER = 404,
  TERMS_AND_CONDITIONS = 405,
  LOAN_BPR_PDA = 406,
  OFFERING_BLOCK_LIST = 407,
  LOAN_BILATERAL = 408,
  PARTNERS = 409,
  EKOSISTEM_KERJA = 13,
  CUSTOMER_DOC = 102,
  AO_BORROWER = 410,
  AO_CUSTOMER_DOC = 411,
  AO_LOAN_DOC = 412,
  LOAN_MEMO = 413
}

export enum ACCESS {
  CREATE = 'buat',
  READ = 'baca',
  UPDATE = 'edit',
  DELETE = 'hapus',
  CAIRKAN = 'cairkan',
  APPROVE = 'setuju'
}

export type FormAccess = {
  hasReadAccess: boolean;
  hasCreateAccess: boolean;
  hasUpdateAccess: boolean;
  hasDeleteAccess: boolean;
  hasCairkanAccess: boolean;
  hasApproveAccess: boolean;
}

interface IPermission {
  user_id: string
  status: number
  role_id: number
  role_name: string
  form_id: string
  baca: number
  buat: number
  edit: number
  hapus: number
  setuju: number
  cairkan: number
}

type UserPermission = IPermission[]

export const getFormAccess = async (permissions: any[], formId: FORM): Promise<FormAccess> => {
  const userPermission = permissions.find((p) => parseInt(p.form_id, 10) === formId);

  return {
    hasReadAccess: userPermission[ACCESS.READ] === 1,
    hasCreateAccess: userPermission[ACCESS.CREATE] === 1,
    hasUpdateAccess: userPermission[ACCESS.UPDATE] === 1,
    hasDeleteAccess: userPermission[ACCESS.DELETE] === 1,
    hasCairkanAccess: userPermission[ACCESS.CAIRKAN] === 1,
    hasApproveAccess: userPermission[ACCESS.APPROVE] === 1,
  };
};

export const checkAccess = async (permissions: any[], formId: FORM, accessType: ACCESS): Promise<boolean> => {
  const userPermission = permissions.find((p) => parseInt(p.form_id, 10) === formId);

  let isAllowed = false;

  try {
    if (userPermission) {
      isAllowed = userPermission[accessType] === 1;
    }
  } catch (e) {
    console.error(e);
  }
  return isAllowed;
};

export const canAccess = ({ form, action }: {form: FORM, action: ACCESS | ACCESS[]}): boolean => {
  let can = false;
  const permissions = JSON.parse(localStorage.getItem('permissions')) as UserPermission;
  if (permissions && permissions.length) {
    const formPermission = permissions.find((p) => parseInt(p.form_id, 10) === form);
    if (formPermission) {
      if (Array.isArray(action) && action.length) {
        const allCan = [];
        action.forEach((a) => {
          allCan.push(formPermission[a] === 1);
        });
        if (allCan.every((v) => v === true)) {
          can = true;
        }
      } else if (formPermission[(action as ACCESS)] === 1) {
        can = true;
      }
    }
  }
  return can;
};

export const checkMultipleAccess = (permissions: any[], formId: FORM[], accessType: ACCESS): any => {
  if (Array.isArray(formId) && formId.length > 0) {
    let userPermissions;
    formId.forEach((fi) => {
      const permission = permissions.find((p) => parseInt(p.form_id, 10) === fi);

      userPermissions = {...userPermissions, [fi]: permission ? permission[accessType] === 1 : false};
    });

    return userPermissions;
  }
  return null;
};

export const normalizePercentValue = (value) => value.toString().replace(/[^0-9]/g, '');

const parsePercent = (number) => {
  let value = number;
  if (!value) {
    value = 0;
  }
  value = normalizePercentValue(value);

  if (!value || Number.isNaN(value)) {
    value = 0;
  }

  const valueInPercent = value / 100;

  const option = {
    maximumSignificantDigits: 4,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat('en-US', option).format(valueInPercent);
};

export default parsePercent;

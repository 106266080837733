import { apiV2 } from './axiosClient';

// eslint-disable-next-line import/prefer-default-export
export const getProjectBD = async () => apiV2.get('/business-development/project-bd');

export const applyProjectAO = async (data) => apiV2.post('/account-officer/loan/apply', data);
export const getAccountOfficers = async () => apiV2.get('/business-development/');
export const getAOById = async (id) => apiV2.get(`/business-development/detail/${id}`);
export const createAO = async (data) => apiV2.post('/business-development/create', data);
export const updateAO = async (id, data) => apiV2.post(`/business-development/${id}`, data);
export const deleteAO = async (id) => apiV2.delete(`/business-development/${id}`);
export const getListNama = async () => apiV2.get('/business-development/list-nama');
export const getUserList = async () => apiV2.get('/business-development/user');

const textStyle = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
};

const heightStyle = {
  xs: 'h-5',
  sm: 'h-8',
  md: 'h-12',
  lg: 'h-16',
  xl: 'h-20',
};

const widthStyle = {
  xs: 'w-5',
  sm: 'w-8',
  md: 'w-12',
  lg: 'w-16',
  xl: 'w-20',
};

const sizeStyle = {
  xs: 'h-5 w-5',
  sm: 'h-8 w-8',
  md: 'h-12 w-12',
  lg: 'h-16 w-16',
  xl: 'h-20 w-20',
};

const marginBottomStyle = {
  xs: 'mb-2',
  sm: 'mb-4',
  md: 'mb-6',
  lg: 'mb-8',
  xl: 'mb-10',
};

const iconSizeStyle = {
  xs: 'h-3 w-3',
  sm: 'h-5 w-5',
  md: 'h-7 w-7',
  lg: 'h-10 w-10',
  xl: 'h-12 w-12',
};

// For MUI
const marginBottomSize = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
};

export {
  heightStyle, widthStyle, sizeStyle, marginBottomSize, marginBottomStyle, iconSizeStyle, textStyle,
};

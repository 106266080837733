/* eslint-disable react/require-default-props */
import React from 'react';
import { ChevronLeftIcon, RefreshIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router';

const PageHeaderDefaultProps = {
  backButton: null,
  children: null,
};

export type PageHeaderProps = {
  title: string;
  backButton?: string | null;
  children?: any;
} & typeof PageHeaderDefaultProps;

function PageHeader({ title, backButton, children }: PageHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap sm:flex-nowrap items-center mb-14 sm:mb-4 gap-4 h-14">
      <div
        className="flex"
      >
        {backButton && (
          <div role="button" onClick={() => { navigate(backButton); }}>
            <ChevronLeftIcon className="block h-14 w-14 hover:text-blue-900" aria-hidden="true" />
          </div>
        )}
        <div className="flex items-center">
          <h3 className="text-2xl font-bold">{title}</h3>
        </div>
      </div>
      {children && (
        <div className="flex items-center gap-1">
          {children}
        </div>
      )}

    </div>
  );
};

PageHeader.defaultProps = PageHeaderDefaultProps;

export type PageHeaderButtonProps = {
  children: any,
  onClick: () => void
};

PageHeader.Button = function ({ children, onClick }: PageHeaderButtonProps) {
  return (
    <div
      role="button"
      className="bg-primary text-white hover:outline-8
      transition-all hover:outline-neutral-900 border-black flex text-md rounded-full focus:ring-white hover:text-blue-300 active:ring-2"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export type PageHeaderRefreshButtonProps = {
  isLoading?: boolean;
  onClick: () => void;
};

PageHeader.RefreshButton = function ({ isLoading = false, onClick }: PageHeaderRefreshButtonProps) {
  const loadingRefreshClass = isLoading ? 'animate-spin cursor-not-allowed' : '';

  return (
    <button
      type="button"
      className="bg-primary text-white hover:outline-8
      transition-all hover:outline-neutral-900 border-black flex text-md rounded-full focus:ring-white hover:text-blue-300 active:ring-2 p-2"
      onClick={onClick}
      disabled={isLoading}
    >
      <RefreshIcon className={`h-4 w-4 hover:text-blue-100 ${loadingRefreshClass}`} aria-hidden="true" />
    </button>
  );
};

export default PageHeader;

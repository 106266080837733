const parseNumber = (number) => {
  let parsed = number;
  try {
    parsed = parseInt(number, 10);
  } catch (e) {
    return 0;
  }
  if (Number.isNaN(parsed)) {
    return 0;
  }

  return new Intl.NumberFormat('id-ID').format(parsed);
};

export default parseNumber;

import React from 'react';
import { Controller, useFormContext} from 'react-hook-form';
import get from 'lodash/get';
import { marginBottomSize } from 'utility/sizeStyle';
import {
  Checkbox, Grid, ListItemText, MenuItem, Select,
} from '@mui/material';
import { classNames } from 'utility/classNames';

interface IOptionProps{
  label: string;
  value: string | number;
}

interface MultiSelectProps {
  disabled?: boolean;
  name: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
  options: IOptionProps[];
}

function MultiSelect(props: MultiSelectProps) {
  const {
    disabled, label, name, required, size, wrap, options,
  } = props;
  const { formState: { errors }, control} = useFormContext();
  const disabledContainerStyle = disabled ? 'bg-gray-50 cursor-not-allowed' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  return (
    <Grid container spacing={2} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        <Controller
          control={control}
          name={name}
          defaultValue={[]}
          rules={{
            required,
          }}
          render={({
            field: {
              onChange, value, onBlur, ref,
            },
          }) => (
            <Select
              disabled={disabled}
              multiple
              value={value}
              onBlur={onBlur}
              ref={ref}
              margin="none"
              style={{width: '100%' }}
              className={classNames(disabledContainerStyle, errorStyle)}
              onChange={onChange}
              size="small"
              renderValue={(renderValue) => {
                if (renderValue && renderValue.length) {
                  return (
                    <div className="flex flex-col">
                      {renderValue.map((v, i) => {
                        if ((i + 1) === renderValue.length) {
                          return (<div className={`text-${size}`} key={v}>{options.find((o) => o.value === v)?.label}</div>);
                        }
                        return (
                          <>
                            <div className={`text-${size}`} key={v}>{options.find((o) => o.value === v)?.label}</div>
                            <div className="h-[1px] bg-gray-200 my-1" />
                          </>
                        );
                      })}
                    </div>
                  );
                }
                return <div />;
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={value.indexOf(option.value) >= 0} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>

          )}
        />
      </Grid>
    </Grid>
  );
}

MultiSelect.defaultProps = {
  disabled: false,
  required: false,
  size: 'xs',
  wrap: true,
};

export default MultiSelect;

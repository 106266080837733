import React, { useEffect } from 'react';
import {
  Controller, useFormContext, useWatch,
} from 'react-hook-form';
import {
  Box, FormGroup, Grid,
} from '@mui/material';
import { marginBottomSize } from 'utility/sizeStyle';
import { FormRadioButton } from '..';

interface IRadioButtonOption{
  value: string | number | boolean | null;
  label: string;
  checked: boolean;
}

interface IRadioButtonGroup {
  disabled?: boolean;
  name: string;
  required?: boolean;
  options: IRadioButtonOption[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  wrap?: boolean;
  spacing?: number
  onChange?: (...args) => void;
  onInputChange?: (...args) => void;
  isLoading?: boolean
  visible?: boolean;
  sizeLabel?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}
function RadioButtonGroup(props:IRadioButtonGroup) {
  const {
    disabled, name, required, options, size, wrap, spacing, alignItems, onChange, onInputChange, isLoading, visible, sizeLabel,
  } = props;

  const { control, formState: { errors }, register } = useFormContext();
  const value = useWatch({ name });

  const [state, setState] = React.useState({ options });

  const handleChange = (event) => {
    const newOptions = state.options.map((o) => (o.value === event.target.name ? { ...o, checked: event.target.checked }
      : { ...o, checked: !(event.target.checked) }));
    setState({ options: newOptions });
    onChange(event, newOptions);
  };

  const inputWidth = () => {
    if (wrap) return 8;
    return 12;
  };

  if (!visible) return null;

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      <Grid item xs={inputWidth()}>

        <FormGroup sx={{ margin: 0 }}>
          <Box sx={{
            display: 'flex', mt: 1,
          }}
          >
            {state.options.map((option) => (
              <FormRadioButton
                key={option.value.toString()}
                label={option.label}
                name={option.value.toString()}
                valueControlled={option.checked}
                isControlled
                onChange={handleChange}
                disabled={disabled}
                visible
                size={sizeLabel}
              />
            ))}
          </Box>
        </FormGroup>

      </Grid>
    </Grid>
  );
}

RadioButtonGroup.defaultProps = {
  disabled: false,
  required: false,
  size: 'xs',
  wrap: true,
  alignItems: 'center',
  spacing: null,
  onChange: () => null,
  onInputChange: () => null,
  isLoading: false,
  visible: true,
  sizeLabel: 'xs',
};

export default RadioButtonGroup;

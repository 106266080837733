import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function AppMeta() {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400&&display=swap" rel="stylesheet" />
      <meta name="theme-color" content="#b8f0c0" />
      <link rel="icon" href="/images/favicon.ico" type="image/ico" sizes="16x16" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />

      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
                (function(a, h){
                    var botsRegexp = /aolbuild|baidu|bingbot|bingpreview|msnbot|duckduckgo|adsbot-google|googlebot|mediapartners-google|teoma|slurp|yandex/gi;
                    window.searchAgentOnPage = h && h==='#noquiz' || botsRegexp.test(a);
                })(navigator.userAgent, location.hash);
            `,
        }}
        id="botDetector"
      />
      <title>KairosCBS - AdminKirana</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="description" content="Internal use only..." />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:site_name" content="depositobpr.id" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

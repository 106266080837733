import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props{
  text?: string | number
}

export default function CopyToClipboard({text}: Props) {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text?.toString() ?? '');
  };

  return (
    <Tooltip title="Copied!" open={isOpenTooltip} placement="right">
      <IconButton
        sx={{ padding: 0 }}
        onClick={() => {
          copyToClipboard();
          setIsOpenTooltip(true);
          setTimeout(() => {
            setIsOpenTooltip(false);
          }, 3000);
        }}
      >
        <ContentCopyIcon className="text-blue-600" color="inherit" fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

CopyToClipboard.defaultProps = {
  text: '',
};

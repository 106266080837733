import {
  Box, Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';
import React, { useEffect } from 'react';

export interface ICheckboxOption{
  value: string | number | boolean | null;
  label: string;
  checked: boolean;
}

export interface ICheckboxGroup {
  parentLabel: string;
  parentChecked?: boolean
  options: ICheckboxOption[];
  onChange?: (...args) => void;
  disabled?: boolean;
}

function CheckboxGroup({
  options, parentLabel, parentChecked, onChange, disabled,
}: ICheckboxGroup) {
  const [state, setState] = React.useState({ options, parentChecked });

  const handleParentChange = (event) => {
    const newOptions = state.options.map((o) => ({ ...o, checked: event.target.checked }));
    setState({ options: newOptions, parentChecked: event.target.checked });
    onChange(event, newOptions);
  };

  const handleChildChange = (event) => {
    const newOptions = state.options.map((o) => (o.value === event.target.value ? { ...o, checked: event.target.checked } : o));
    const newParentChecked = newOptions.every((o) => o.checked);
    setState({ options: newOptions, parentChecked: newParentChecked });
    onChange(event, newOptions);
  };

  let indeterminate = false;
  let allChecked = true;
  state.options.forEach((option) => {
    if (!option.checked) {
      allChecked = false;
    }
    if (option.checked) {
      indeterminate = true;
    }
  });

  useEffect(() => {
    const newParentChecked = options.every((o) => o.checked);
    setState({parentChecked: newParentChecked, options});
  }, [options]);

  return (

    <FormGroup sx={{ margin: 0 }}>
      <FormControlLabel
        control={(
          <Checkbox
            checked={state.parentChecked}
            onChange={handleParentChange}
            value="parent"
            indeterminate={indeterminate && !allChecked}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={disabled}
            size="small"
          />
        )}
        label={parentLabel}
        componentsProps={{
          typography: {
            fontSize: 11,
          },
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {state.options.map((option) => (
          <FormControlLabel
            key={option.value.toString()}
            control={(
              <Checkbox
                checked={option.checked}
                onChange={handleChildChange}
                value={option.value}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={disabled}
                size="small"
              />
            )}
            label={option.label}
            componentsProps={{
              typography: {
                fontSize: 11,
              },
            }}
            sx={{ margin: 0 }}
          />
        ))}
      </Box>
    </FormGroup>
  );
}

CheckboxGroup.defaultProps = {
  disabled: false,
  onChange: () => null,
  parentChecked: false,
};

export default CheckboxGroup;

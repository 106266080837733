import { createContext, useContext } from 'react';

export interface AppContextType {
  scrollTo:{
    id: string | null;
    setId: (id: string | null) => void;
  } | null
}

export const AppContext = createContext<AppContextType | null>(null);
export const useAppContext = () => useContext(AppContext);

import { Button } from 'components/basic';
import * as React from 'react';
import {
  Control, useFieldArray, useFormContext, useWatch,
} from 'react-hook-form';
import { Grid } from '@mui/material';
import { classNames } from 'utility/classNames';
import { marginBottomSize } from 'utility/sizeStyle';
import AutoCompleteControlledV2 from '../autocomplete/AutocompleteControlledV2';

interface Options {
  name: string;
  value: string | number;
  key?: string;
}

interface Props{
  name: string;
  selectOptions?: Options[];
  readOnly?: boolean;
  disabled?: boolean;
  allowDelete?: boolean;
  allowAdd?: boolean;
  label?: string;
  wrap?: boolean;
  spacing?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  required?: boolean;
  noDuplicates?: boolean;
}

function Row({ control, name, index }: { control: Control, name: string, index: number }) {
  const rowValue = useWatch({
    name: `${name}.${index}`,
    control,
  });
  return (
    <p>
      {rowValue}
    </p>
  );
}

function InputArray({
  name, selectOptions, readOnly, disabled, noDuplicates,
  required, allowDelete, allowAdd, label, spacing, wrap, size,
}: Props) {
  const { control } = useFormContext();
  const formValues = useWatch({
    name,
    control,
  });
  const {fields, append, remove} = useFieldArray({name, control, rules: {required} });
  const [addValue, setAddValue] = React.useState(null);
  const selectOptionsFiltered = selectOptions.filter((so) => !formValues?.some((fv) => fv === so.value));

  const inputWidth = () => {
    if (!label) return 12;
    if (wrap) return 8;
    return 12;
  };

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} mb={marginBottomSize[size]}>
      {label && (
        <Grid item xs={wrap ? 4 : 12}>
          <label htmlFor={name} className={`text-${size}`}>{label}</label>
          {required && !disabled && <span className="text-red-600">*</span>}
        </Grid>
      )}
      <Grid item xs={inputWidth()}>
        {fields.length < 1 && (
          <div className="w-full h-auto border border-dashed border-gray-200 bg-gray-50 text-gray-500 pl-1">-</div>
        )}
        <div className="max-h-32 overflow-auto">
          <table className="border-collapse border border-gray-400 w-full">
            <tbody className={`text-${size}`}>
              {fields.map((field, index) => (
                <tr key={field.id}>
                  <td className="border border-gray-400 p-1">
                    <Row control={control} name={name} index={index} />
                  </td>
                  {!readOnly && allowDelete && (
                    <td className="border border-gray-400 text-center w-7">
                      <Button
                        onClick={() => remove(index)}
                        value={(
                          <i className={classNames(
                            'dx-icon-trash cursor-pointer',
                            disabled ? 'text-gray-400' : 'text-red-600',
                            `text-${size}`,
                          )}
                          />
                        )}
                        color="transparent"
                        className="px-4"
                        disabled={disabled}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {!readOnly && allowAdd && (
          <div>
            <table className="w-full border-collapse">
              <tbody>
                <tr>
                  <td className="border border-gray-400">
                    <AutoCompleteControlledV2
                      name=""
                      shouldUnregister
                      isControlled
                      options={noDuplicates ? selectOptionsFiltered : selectOptions}
                      wrap={false}
                      onChange={(v) => { setAddValue(v); }}
                      value={addValue}
                      mb={0}
                      size={size}
                    />
                  </td>
                  <td className="border border-gray-400 text-center w-12">
                    <Button
                      onClick={() => { append(addValue); if (noDuplicates) { setAddValue(null); } }}
                      value={<i className={classNames('dx-icon-add cursor-pointer', `text-${size}`)} />}
                      color="transparent"
                      className="px-6"
                      disabled={disabled || !addValue}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

InputArray.defaultProps = {
  selectOptions: [],
  readOnly: false,
  disabled: false,
  allowDelete: true,
  allowAdd: true,
  label: false,
  wrap: true,
  spacing: undefined,
  size: 'xs',
  required: false,
  noDuplicates: false,
};

export default InputArray;

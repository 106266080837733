import React from 'react';
import { Grid } from '@mui/material';
import { useFormContext} from 'react-hook-form';
import get from 'lodash/get';
import { marginBottomSize, textStyle } from 'utility/sizeStyle';
import { classNames } from 'utility/classNames';

export interface RadioButtonProps {
  disabled?: boolean;
  name: string;
  onChange?: (...args) => void;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  spacing?: number
  visible?: boolean;
  id?: string;
  value: string | number
}

function RadioButton(props: RadioButtonProps) {
  const {
    name, required, label, disabled, size, onChange, alignItems, spacing, visible, id, value, ...rest
  } = props;
  const { register, formState: { errors } } = useFormContext();
  const disabledStyle = disabled ? 'border-gray-200 text-gray-300 cursor-not-allowed' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  if (!visible) return null;

  return (
    <Grid container spacing={spacing || 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      <Grid item xs="auto">
        <input
          {...register(name, {
            required,
            onChange,
          })}
          {...rest}
          className={`p-1 border border-gray-300 focus:outline-none focus:ring-0 ${disabledStyle} ${errorStyle}`}
          disabled={disabled}
          type="radio"
          id={id}
          value={value}
        />
      </Grid>
      <Grid item xs>
        <span className={classNames(textStyle[size], disabledStyle)}>
          {' '}
          <label htmlFor={id}>{label}</label>
          {' '}
        </span>
      </Grid>
    </Grid>
  );
}

RadioButton.defaultProps = {
  disabled: false,
  onChange: () => null,
  required: false,
  size: 'xs',
  alignItems: 'center',
  spacing: null,
  visible: true,
  id: undefined,
};

export default RadioButton;

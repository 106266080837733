import FormField from 'components/FormField';
import React, { useState, useEffect } from 'react';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ErrorCaption from '../error-caption/ErrorCaption';

export interface selectProps {
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: (...args) => void;
  options: {
    name: string,
    value: string | number,
  }[];
  value: number | string;
  error?: string;
  height?: number;
  fontSize?: number;
}

function Select(props: selectProps) {
  const {
    onChange, value, label, name, disabled, options, error, height, fontSize,
  } = props;
  const [selectedValue, setSelectedvalue] = useState(value);

  const onInputChange = (e) => {
    const val = e.target.value;
    setSelectedvalue(val);
    onChange(val, name);
  };

  useEffect(() => {
    setSelectedvalue(value);
  }, [value]);

  return (
    <>
      <FormField label={label}>
        <MuiSelect
          disabled={disabled}
          value={selectedValue}
          onChange={onInputChange}
          placeholder="Select"
          label="Select"
          sx={{ width: '100%', height, fontSize }}
          SelectDisplayProps={{
            style: {
              margin: -8,
            },
          }}
        >
          {options.map((option) => (<MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>))}
        </MuiSelect>
      </FormField>
      {error !== '' && (
        <ErrorCaption error={error} />
      )}
    </>
  );
}

Select.defaultProps = {
  disabled: false,
  onChange: () => null,
  label: '',
  error: '',
  height: 25,
  fontSize: 12,
};

export default Select;

import * as React from 'react';
import {
  Controller, useFormContext,
} from 'react-hook-form';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import { classNames } from 'utility/classNames';
import { Grid } from '@mui/material';
import { SearchIcon } from '@heroicons/react/outline';

interface AutoCompleteControlledV2Props<
  O extends { name: string; value: string | number },
> {
  name:string;
  options: O[];
  placeholder?: string;
  onChange?: (v: string | number) => void;
  onInputChange?: (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => void,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  wrap?: boolean;
  spacing?: number
  isLoading?: boolean
  showInlineError?: boolean
  shouldUnregister?: boolean;
  value?: string | number;
  mb?: number;
  isControlled?: boolean
}

const iconSize = {
  xs: 'h-3',
  sm: 'h-5',
  md: 'h-8',
  lg: 'h-12',
  xl: 'h-16',
};

function AutoCompleteControlledV2<
  O extends { name: string; value: string | number },
>({
  name, options, placeholder, onChange: onChangeProps, size, readOnly, disabled, required, label, alignItems,
  spacing, wrap, onInputChange, isLoading, showInlineError, shouldUnregister, value: propValue, mb, isControlled,
}: AutoCompleteControlledV2Props<O>) {
  const { control } = useFormContext();

  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed text-inherit' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const className = `
  p-1 border text-${size} border-gray-200 w-full focus:outline-none placeholder:text-gray-400 
  ${heightStyle[size]} ${readOnly ? readOnlyStyle : disabledStyle}`;
  const inputWidth = () => {
    if (!label) return 12;
    if (wrap) return 8;
    return 12;
  };

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={mb ?? marginBottomSize[size]}>
      {label && (
        <Grid item xs={wrap ? 4 : 12}>
          <label htmlFor={name} className={`text-${size}`}>{label}</label>
          {required && !disabled && <span className="text-red-600">*</span>}
        </Grid>
      )}
      <Grid item xs={inputWidth()}>
        <Controller
          name={name}
          shouldUnregister={shouldUnregister}
          control={control}
          rules={{
            required: required ? `Field '${label}' is required` : false,
          }}
          render={({ field, fieldState: { error } }) => {
            const { onChange, value: fieldValue, ref } = field;
            const value = isControlled ? propValue : fieldValue;
            return (
              <>
                <Autocomplete
                  value={
                    value
                      ? options.find((option) => value === option.value) ?? null
                      : null
                  }
                  getOptionLabel={(option) => (option as O).name}
                  onChange={(_, newValue) => {
                    onChange(newValue ? (newValue as O).value : null);
                    onChangeProps(newValue ? (newValue as O).value : null);
                  }}
                  ref={ref}
                  size="small"
                  onInputChange={onInputChange}
                  loading={isLoading}
                  options={options}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref} className="relative">
                      <input
                        {...params.inputProps}
                        className={classNames(className)}
                        readOnly={readOnly}
                        disabled={disabled}
                        placeholder={placeholder}
                      />
                      <SearchIcon className={`absolute mr-[2%] top-1/2 right-0 transform -translate-y-1/2 ${iconSize[size]} text-gray-400 `} />
                    </div>
                  )}
                />
                {showInlineError && error && (
                  <div className={`flex w-full justify-end text-red-600 text-${size} mb-${marginBottomSize[size]} mt-0.5`}>{error.message}</div>
                )}
              </>
            );
          }}
        />
      </Grid>
    </Grid>

  );
}

AutoCompleteControlledV2.defaultProps = {
  placeholder: 'Search...',
  onChange: () => null,
  size: 'xs',
  readOnly: false,
  disabled: false,
  required: false,
  label: false,
  wrap: true,
  alignItems: 'center',
  spacing: null,
  onInputChange: () => null,
  isLoading: false,
  showInlineError: false,
  shouldUnregister: false,
  value: undefined,
  mb: undefined,
  isControlled: false,
};

export default AutoCompleteControlledV2;

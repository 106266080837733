import axios from 'axios';

const getToken = () => localStorage.getItem('authToken');

export const apiV1 = axios.create({
  baseURL: process.env.REACT_APP_API_V1_URL || '/api/v1',
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
});

export const apiV2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL || '/api-core',
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
});

export const apiV2Multipart = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL || '/api-core',
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'multipart/form-data',
  },
});

export const apiAFPI = axios.create({
  baseURL: process.env.REACT_APP_API_AFPI || '/api/afpi',
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
});

const redirect = () => {
  window.localStorage.clear();
  const currentUrl = encodeURIComponent(window.location.pathname + window.location.search);
  window.location.href = `/login?redirect=${currentUrl}`;
};

apiV1.interceptors.request.use(
  (config) => {
    const updatedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getToken() || ''}`,
      },
    };

    return updatedConfig;
  },
  (error) => Promise.reject(error),
);

apiV2.interceptors.request.use(
  (config) => {
    const updatedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getToken() || ''}`,
      },
    };

    return updatedConfig;
  },
  (error) => Promise.reject(error),
);

apiV2Multipart.interceptors.request.use(
  (config) => {
    const updatedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getToken() || ''}`,
      },
    };

    return updatedConfig;
  },
  (error) => Promise.reject(error),
);

apiAFPI.interceptors.request.use(
  (config) => {
    const updatedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getToken() || ''}`,
      },
    };

    return updatedConfig;
  },
  (error) => Promise.reject(error),
);

apiV1.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      redirect();
    }
    return Promise.reject(error);
  },
);

apiV2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      redirect();
    }
    return Promise.reject(error);
  },
);

apiV2Multipart.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      redirect();
    }
    return Promise.reject(error);
  },
);

apiAFPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      redirect();
    }
    return Promise.reject(error);
  },
);

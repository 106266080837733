import { apiV1, apiV2 } from './axiosClient';

const basePath = 'user';

export const getPermission = async () => apiV2.get(`${basePath}/permission`);

export const login = async (email, password) => apiV2.post('/user/login', { email, password });

export const changePassword = async (data) => apiV2.post('/user/change-password', data);

export const getListUserRoles = async () => apiV2.get('/user-roles/');
export const createUserRoles = async (data) => apiV2.post('user-roles/create', data);
export const deleteUserRoles = async (id) => apiV2.delete(`user-roles/${id}`);

export const getListAdmin = async () => apiV2.get('/masteradmin');
export const editAdmin = async (userId, data) => apiV2.post(`/masteradmin/${userId}`, data);
export const createAdmin = async (data) => apiV2.post('/masteradmin/create', data);

export const getListRoles = async () => apiV2.get('/masterroles/');
export const editRoles = async (roleId, data) => apiV2.post(`/masterroles/${roleId}`, data);
export const createRoles = async (data) => apiV2.post('/masterroles/create', data);
export const ejectRoles = async (roleId) => apiV2.post(`/masterroles/eject/${roleId}`);

export const getListForm = async () => apiV2.get('/masterform/');
export const createForm = async (data) => apiV2.post('/masterform/create', data);
export const editForm = async (formId, data) => apiV2.post(`/masterform/${formId}`, data);
export const deleteForm = async (formId) => apiV2.delete(`/masterform/${formId}`);

export const getListFormRoles = async () => apiV2.get('/form-roles/');
export const createFormRoles = async (data) => apiV2.post('/form-roles/create', data);
export const editFormRoles = async (formRolesId, data) => apiV2.post(`/form-roles/${formRolesId}`, data);
export const deleteFormRoles = async (formRolesId) => apiV2.delete(`/form-roles/${formRolesId}`);

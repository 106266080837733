import React, {
  useEffect, useState, lazy, Suspense,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import {
  Alert, AlertColor, Snackbar,
} from '@mui/material';
import { SnackbarContext } from 'context/snackbar';
import NavBar from 'components/navbar/NavBar';
import LoginV1 from 'screen/login-v1/LoginV1';
import { getPermission } from 'client/request/admin';
import { useIsAuthenticated } from '@azure/msal-react';
import { Spinner } from 'components/basic';
import { AppContext } from 'context/app';
import AddEditAccountOfficer from 'screen/BusinessDevelopment/AddEditAccountOfficer';
import { FORM } from 'utility/moduleAccess';
import { UserContext } from './context/user';
import Footer from './components/footer/Footer';

// use lazy import to improve initial page load
const LoanList = lazy(() => import('screen/Loan/LoanList'));
const Login = lazy(() => import('screen/Login/Login'));
const LoanEdit = lazy(() => import('screen/Loan/LoanEdit'));
const DanaMengendap = lazy(() => import('screen/DanaMengendap/DanaMengendap'));
const BorrowerList = lazy(() => import('screen/Borrower/BorrowerList'));
const BorrowerCreate = lazy(() => import('screen/Borrower/create/BorrowerCreate'));
const BorrowerEdit = lazy(() => import('screen/Borrower/BorrowerEdit'));
const BorrowerGroup = lazy(() => import('screen/BorrowerGroup/BorrowerGroup'));
const BorrowerGroupEdit = lazy(() => import('screen/BorrowerGroup/BorrowerGroupEdit'));
const Lender = lazy(() => import('screen/Lender/Lender'));
const LenderEdit = lazy(() => import('screen/Lender/LenderEdit'));
const PengajuanProjectAO = lazy(() => import('screen/BusinessDevelopment/PengajuanProjectBD/PengajuanProjectAO'));
const ListFaktur = lazy(() => import('screen/ManajemenFaktur/ListFaktur'));
const LoginOAuth365 = lazy(() => import('screen/LoginOAuth365/LoginOAuth365'));
const Home = lazy(() => import('screen/Home'));
const DebetRDL = lazy(() => import('screen/DebetRDL/DebetRDL'));
const Promosi = lazy(() => import('screen/Promosi/Promosi'));
const PromosiEdit = lazy(() => import('screen/Promosi/section/PromosiForm'));
const PromosiCreate = lazy(() => import('screen/Promosi/section/PromosiForm'));
const LoanAddCollection = lazy(() => import('screen/Loan/LoanCollection/section/loanAddCollection'));
const LoanEditCollection = lazy(() => import('screen/Loan/LoanCollection/section/loanEditCollection'));
const InstallmentEdit = lazy(() => import('screen/Loan/Installment/InstallmentEditForm'));
const IncomeEdit = lazy(() => import('screen/Loan/Income/IncomeEditForm'));
const AkunBankEdit = lazy(() => import('screen/ListAkunBank/AkunBankEdit'));
const ListAkunBank = lazy(() => import('screen/ListAkunBank/ListAkunBank'));
const ListBuyerProfile = lazy(() => import('screen/BuyerProfile/BuyerProfile'));
const AccountOfficer = lazy(() => import('screen/BusinessDevelopment/MasterAccountOfficer'));
const ListMasterAdmin = lazy(() => import('screen/Admin/MasterAdmin/ListMasterAdmin'));
const ListMasterRoles = lazy(() => import('screen/Admin/MasterRoles/ListMasterRoles'));
const ListMasterEmail = lazy(() => import('screen/Admin/MasterEmail/MasterEmail'));
const MasterEmailEdit = lazy(() => import('screen/Admin/MasterEmail/EditMasterEmail'));
const ListMasterForm = lazy(() => import('screen/Admin/MasterForm/ListMasterForm'));
const ListFormRoles = lazy(() => import('screen/Admin/FormRoles/ListFormRoles'));
const ListUserRoles = lazy(() => import('screen/Admin/UserRoles/ListUserRoles'));
const ListDisbursementOrder = lazy(() => import('screen/DisbursementOrder/DisbursementOrder'));
const AutoLending = lazy(() => import('screen/AutoLending/AutoLending'));
const AddAutoLending = lazy(() => import('screen/AutoLending/AddAutoLending'));
const AutoLendingList = lazy(() => import('screen/AutoLendingList/AutoLendingList'));
const TermsAndConditions = lazy(() => import('screen/TermsAndConditions/TermsAndConditions'));
const TermsAndConditionsEdit = lazy(() => import('screen/TermsAndConditions/TermsAndConditionsEdit'));
const ListPartners = lazy(() => import('screen/Admin/Partners/Partners'));
const ListOfferingLenderBlocklists = lazy(
  () => import('screen/OfferingLenderBlocklist/ListOfferingLenderBlocklists'),
);
const ShortLinkRoute = lazy(() => import('components/ShortLinkRoute'));

export default function AllRoutes() {
  const [snackbar, setSnackbar] = useState({
    show: false,
    message: '',
    severity: 'success',
  });
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [permission, setPermission] = useState([]);
  const [scrollToId, setScrollToId] = useState<string | null>(null);
  const microsoftIsAuthenticated = useIsAuthenticated();
  const [oAuth365Done, setOAuth365Done] = useState(false);

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prevState) => ({...prevState, show: false}));
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const tokenExpiredAt = localStorage.getItem('tokenExpiredAt');
    if (token && tokenExpiredAt) {
      const currentDate = new Date();
      const tokenExpiredDate = new Date(tokenExpiredAt);
      if (currentDate <= tokenExpiredDate) return setAuthenticated(true);
    }
    if (!window.location.search.includes('redirect')) {
      const currentUrl = window.location.pathname + window.location.search;
      const newSearch = `?redirect=${encodeURIComponent(currentUrl)}`;
      window.history.replaceState({}, '', newSearch);
    }
    return setAuthenticated(false);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    const storedPermission = JSON.parse(localStorage.getItem('permissions'));
    if (storedPermission) {
      setPermission(storedPermission);
    } else {
      getPermission().then((res) => {
        setPermission(res.data.payload.permission);
        localStorage.setItem('permissions', JSON.stringify(res.data.payload.permission));
      });
    }
  }, [isAuthenticated]);

  return (
    <AppContext.Provider value={{scrollTo: {id: scrollToId, setId: setScrollToId}}}>
      <UserContext.Provider
        value={{
          isAuthenticated,
          setAuthenticated: (v) => setAuthenticated(v),
          permission,
          setPermission: (v) => setPermission(v),
          role: {
            id: permission[0]?.role_id,
            name: permission[0]?.role_name,
          },
        }}
      >
        <SnackbarContext.Provider
          value={{
            show: (message, severity = 'success') => {
              setSnackbar({ show: true, message, severity });
            },
          }}
        >
          <Suspense
            fallback={(
              <div className="h-screen w-full flex justify-center items-center">
                <Spinner color="blue" size="sm" flexGrow={0} />
              </div>
            )}
          >
            <Router>
              <div className="flex flex-col justify-between min-h-screen">
                <NavBar />
                <Snackbar
                  open={snackbar.show}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity as AlertColor}
                    sx={{ width: '100%' }}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
                <div className="root-app mx-auto">
                  {isAuthenticated && (
                    <Routes>
                      <Route
                        caseSensitive
                        path="/f"
                        element={<ShortLinkRoute />}
                      />
                      <Route
                        caseSensitive
                        path="/"
                        element={<Home />}
                      />
                      <Route
                        caseSensitive
                        path="/project"
                        element={<LoanList />}
                      />
                      <Route
                        caseSensitive
                        path="/project/:id"
                        element={<LoanEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/project/collection/create/:id"
                        element={<LoanAddCollection />}
                      />
                      <Route
                        caseSensitive
                        path="/project/collection/get/:id"
                        element={<LoanEditCollection />}
                      />
                      <Route
                        caseSensitive
                        path="/project/:loanId/installment/:id"
                        element={<InstallmentEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/project/:loanId/income/:id"
                        element={<IncomeEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/borrower"
                        element={<BorrowerList />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/borrower"
                        element={<BorrowerList form={FORM.AO_BORROWER} />}
                      />
                      <Route
                        caseSensitive
                        path="/borrower/create"
                        element={<BorrowerCreate />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/borrower/create"
                        element={<BorrowerCreate form={FORM.AO_BORROWER} />}
                      />
                      <Route
                        caseSensitive
                        path="/borrower/:id"
                        element={<BorrowerEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/borrower/:id"
                        element={<BorrowerEdit form={FORM.AO_BORROWER} />}
                      />
                      <Route
                        caseSensitive
                        path="/lender"
                        element={<Lender />}
                      />
                      <Route
                        caseSensitive
                        path="/lender/:id"
                        element={<LenderEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/borrowergroup"
                        element={<BorrowerGroup />}
                      />
                      <Route
                        caseSensitive
                        path="/borrowergroup/:id"
                        element={<BorrowerGroupEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/danamengendap"
                        element={<DanaMengendap />}
                      />
                      <Route
                        caseSensitive
                        path="/akunbanklist"
                        element={<ListAkunBank />}
                      />
                      <Route
                        caseSensitive
                        path="/buyerprofile"
                        element={<ListBuyerProfile />}
                      />
                      <Route
                        caseSensitive
                        path="/akunbanklist/get/:id"
                        element={<AkunBankEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer"
                        element={<AccountOfficer />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/edit/:id"
                        element={<AddEditAccountOfficer type="EDIT" />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/add"
                        element={<AddEditAccountOfficer type="ADD" />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/project"
                        element={<LoanList form={FORM.AO_LOAN} />}
                      />
                      <Route
                        caseSensitive
                        path="/pengajuan-project-ao"
                        element={<PengajuanProjectAO />}
                      />
                      <Route
                        caseSensitive
                        path="/account-officer/project/:id"
                        element={<LoanEdit form={FORM.AO_LOAN} />}
                      />
                      <Route
                        caseSensitive
                        path="/manajemenfaktur"
                        element={<ListFaktur />}
                      />
                      <Route
                        caseSensitive
                        path="/manajemenfaktur/:invoiceId"
                        element={<ListFaktur />}
                      />
                      <Route
                        caseSensitive
                        path="/promosi"
                        element={<Promosi />}
                      />
                      <Route
                        caseSensitive
                        path="/promosi/create"
                        element={(
                          <PromosiCreate
                            title="Buat Promosi"
                            type="CREATE"
                          />
                        )}
                      />
                      <Route
                        caseSensitive
                        path="/promosi/:id"
                        element={(
                          <PromosiEdit
                            title="Edit Promosi"
                            type="UPDATE"
                          />
                        )}
                      />
                      <Route
                        caseSensitive
                        path="/debetrdl"
                        element={<DebetRDL />}
                      />
                      <Route
                        caseSensitive
                        path="/userroles"
                        element={<ListUserRoles />}
                      />
                      <Route
                        caseSensitive
                        path="/masteradmin"
                        element={<ListMasterAdmin />}
                      />
                      <Route
                        caseSensitive
                        path="/masterroles"
                        element={<ListMasterRoles />}
                      />
                      <Route
                        caseSensitive
                        path="/masteremail"
                        element={<ListMasterEmail />}
                      />
                      <Route
                        caseSensitive
                        path="/masteremail/get/:id"
                        element={<MasterEmailEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/masterform"
                        element={<ListMasterForm />}
                      />
                      <Route
                        caseSensitive
                        path="/formroles"
                        element={<ListFormRoles />}
                      />
                      <Route
                        caseSensitive
                        path="/disbursement-order"
                        element={<ListDisbursementOrder />}
                      />
                      <Route
                        caseSensitive
                        path="/auto-lending"
                        element={<AutoLending />}
                      />
                      <Route
                        caseSensitive
                        path="/auto-lending/get/:id/:idCustomer"
                        element={<AddAutoLending />}
                      />
                      <Route
                        caseSensitive
                        path="/auto-lending-list"
                        element={<AutoLendingList />}
                      />
                      <Route
                        caseSensitive
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                      />
                      <Route
                        caseSensitive
                        path="/terms-and-conditions/add"
                        element={<TermsAndConditionsEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/terms-and-conditions/edit/:id"
                        element={<TermsAndConditionsEdit />}
                      />
                      <Route
                        caseSensitive
                        path="/projectkirana"
                        element={<LoanList form={FORM.LOAN_BPR_PDA} />}
                      />
                      <Route
                        caseSensitive
                        path="/projectkirana/:id"
                        element={<LoanEdit form={FORM.LOAN_BPR_PDA} />}
                      />
                      <Route
                        caseSensitive
                        path="/partners/index"
                        element={<ListPartners />}
                      />
                      <Route
                        caseSensitive
                        path="/offeringlenderblocklist"
                        element={
                          <ListOfferingLenderBlocklists />
                        }
                      />
                      <Route path="*" element={<Home />} />
                    </Routes>
                  )}
                  {!isAuthenticated && (
                    <Routes>
                      <Route
                        path="/login"
                        element={(
                          <Login
                            setOAuth365Done={
                              setOAuth365Done
                            }
                          />
                        )}
                      />
                      <Route
                        path="/login/token"
                        element={<LoginV1 />}
                      />
                      <Route
                        path="*"
                        element={(
                          <Login
                            setOAuth365Done={
                              setOAuth365Done
                            }
                          />
                        )}
                      />
                    </Routes>
                  )}
                  {microsoftIsAuthenticated && oAuth365Done && (
                    <Routes>
                      <Route
                        path="*"
                        element={<LoginOAuth365 />}
                      />
                    </Routes>
                  )}
                </div>
                <div className="flex-1" />
                <Footer />
              </div>
            </Router>
          </Suspense>
        </SnackbarContext.Provider>
      </UserContext.Provider>
    </AppContext.Provider>
  );
}

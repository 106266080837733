import React from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import {
  marginBottomSize,
} from 'utility/sizeStyle';

export interface SwitchProps {
  disabled?: boolean;
  name: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onChange?: (...args) => void;
  readOnly?: boolean;
}

function Switch(props: SwitchProps) {
  const {
    name, required, label, disabled, size, onChange, readOnly, ...rest
  } = props;
  const { register, formState: { errors } } = useFormContext();
  const checked = useWatch({ name });
  const disabledStyle = disabled ? 'border-gray-200 text-inherit cursor-not-allowed' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'border-gray-300 text-black cursor-not-allowed' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'border-red-600' : '';
  const checkedBgStyle = checked ? 'bg-blue-600 border-none' : '';
  const checkedDisabledStyle = checked && disabled ? 'bg-gray-500 cursor-not-allowed' : '';
  const checkedReadOnlyStyle = checked && readOnly ? 'bg-gray-800 cursor-not-allowed' : '';
  const checkedDotStyle = checked ? 'bg-white translate-x-full' : 'bg-gray-400';

  return (
    <Grid container spacing={1} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs="auto">
        <label htmlFor={`switch-${name}`} className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              {...register(name, {
                required, onChange,
              })}
              {...rest}
              className="sr-only checked:bg-blue-600"
              disabled={readOnly || disabled}
              id={`switch-${name}`}
              type="checkbox"
              readOnly={readOnly}
            />
            <div className={`border w-8 h-5 rounded-full ${checkedBgStyle} 
            ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle} ${checkedDisabledStyle} ${checkedReadOnlyStyle}`}
            />
            <div className={`absolute left-1 top-1 w-3 h-3 rounded-full transition 
            ${checkedDotStyle} ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}`}
            />
          </div>
        </label>
      </Grid>
      <Grid item xs>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
    </Grid>
  );
}

Switch.defaultProps = {
  disabled: false,
  required: false,
  onChange: () => null,
  size: 'xs',
  readOnly: false,
};

export default Switch;

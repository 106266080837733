import { Button, Spinner } from 'components/basic';
import React, { useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

interface FormProps {
  onSubmit?: (...args) => void;
  children: React.ReactNode;
  initialValues: FieldValues | Promise<FieldValues>;
  hideButton?: boolean;
  disableButton?: boolean;
  buttonText?: string;
  buttonClassName?: string;
  isLoading?: boolean;
  id?: string;
  toggleTrigger?: boolean;
  fieldsToUnregister?: string[]
}
function Form(props: FormProps) {
  const {
    onSubmit, children, initialValues, hideButton, disableButton, buttonText, isLoading,
    buttonClassName, id, toggleTrigger, fieldsToUnregister,
  } = props;
  const method = useForm({
    defaultValues: initialValues,
    // shouldUnregister: true,
  });
  const {
    handleSubmit, reset, formState, trigger, unregister,
  } = method;
  const { isSubmitting, isValid, errors } = formState;

  useEffect(() => {
    reset({ ...initialValues });
  }, [initialValues]);

  useEffect(() => {
    trigger();
  }, [toggleTrigger]);

  useEffect(() => {
    if (fieldsToUnregister) { unregister(fieldsToUnregister); }
  }, [fieldsToUnregister]);

  return (
    <FormProvider {...method}>
      <form
        className="relative"
        onSubmit={
          (e) => {
            e.preventDefault();
            e.stopPropagation();

            return handleSubmit((data, event) => {
              onSubmit(data, event);
            })(e);
          }
        }
        id={id}
      >
        {(isLoading || isSubmitting) && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-50 animate-pulse z-10">
            <Spinner color="blue" size="sm" flexGrow={0} />
          </div>
        )}
        {children}
        {!hideButton
            && (
              <div className="text-right mt-4">
                {!isValid && (
                  <span className="mb-2 block text-red-600 text-xs">
                    Field dengan * wajib diisi sebelum menekan tombol
                    {' '}
                    {buttonText}
                  </span>
                )}
                <Button
                  className={buttonClassName}
                  disabled={disableButton || !isValid || isSubmitting || isLoading || !isEmpty(errors)}
                  form={id}
                  isLoading={isLoading || isSubmitting}
                  type="submit"
                  value={buttonText}
                />
              </div>
            )}
      </form>
    </FormProvider>
  );
}

Form.defaultProps = {
  onSubmit: () => null,
  hideButton: false,
  disableButton: false,
  buttonText: 'Save',
  buttonClassName: undefined,
  isLoading: false,
  id: undefined,
  toggleTrigger: undefined,
  fieldsToUnregister: undefined,
};

export default Form;

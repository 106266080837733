import React from 'react';
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
import { RefCallBack } from 'react-hook-form';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Poppins',
];
const headerValues = [false, 1, 2, 3, 4, 5];

type ITextEditorProps = {
  className?: string,
  disabled?: boolean,
  height?: number | string,
  multilineToolbar?: boolean,
  onBlur?: (...args) => void,
  onChange?: (...args) => void,
  value: string,
  refs?: RefCallBack,
};

function TextEditor(props: ITextEditorProps) {
  const {
    multilineToolbar, onBlur, onChange, value, refs, className, disabled, height,
  } = props;
  const handleValueChange = (e) => onChange(e.value);

  return (
    <HtmlEditor
      className={className}
      disabled={disabled}
      height={height}
      value={value}
      valueType="html"
      onValueChanged={handleValueChange}
      onFocusOut={onBlur}
      ref={refs}
    >
      <Toolbar multiline={multilineToolbar}>
        <Item name="undo" />
        <Item name="redo" />
        <Item name="separator" />
        <Item name="size" acceptedValues={sizeValues} />
        <Item name="font" acceptedValues={fontValues} />
        <Item name="separator" />
        <Item name="bold" />
        <Item name="italic" />
        <Item name="strike" />
        <Item name="underline" />
        <Item name="separator" />
        <Item name="alignLeft" />
        <Item name="alignCenter" />
        <Item name="alignRight" />
        <Item name="alignJustify" />
        <Item name="separator" />
        <Item name="orderedList" />
        <Item name="bulletList" />
        <Item name="separator" />
        <Item name="header" acceptedValues={headerValues} />
        <Item name="separator" />
        <Item name="color" />
        <Item name="background" />
        <Item name="separator" />
        <Item name="link" />
        <Item name="image" />
        <Item name="separator" />
        <Item name="clear" />
        <Item name="codeBlock" />
        <Item name="blockquote" />
        <Item name="separator" />
        <Item name="insertTable" />
        <Item name="deleteTable" />
        <Item name="insertRowAbove" />
        <Item name="insertRowBelow" />
        <Item name="deleteRow" />
        <Item name="insertColumnLeft" />
        <Item name="insertColumnRight" />
        <Item name="deleteColumn" />
      </Toolbar>
    </HtmlEditor>
  );
};

TextEditor.defaultProps = {
  className: '',
  disabled: false,
  height: 300,
  multilineToolbar: true,
  onBlur: () => null,
  onChange: () => null,
  refs: null,
};

export default TextEditor;

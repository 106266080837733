import React from 'react';

import './style.css';

export function Footer() {
  return (
    <div className="footer">
      <div className="footer-copyright text-center">
        <div>
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Powered By PT Komunal Teknologi Indonesia
        </div>
      </div>
    </div>
  );
}

export default Footer;

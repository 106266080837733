import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { heightStyle, iconSizeStyle, marginBottomStyle } from 'utility/sizeStyle';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

interface PasswordProps {
  confirmPasswordName?: string;
  disabled?: boolean;
  name: string;
  onChange?: (...args) => void;
  placeholder?: string;
  required?: boolean;
  label: string;
  maxLength?: number;
  minLength?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  validation?: string;
}

function Password(props: PasswordProps) {
  const {
    name, required, label, disabled, size, maxLength, minLength, onChange, confirmPasswordName, validation, ...rest
  } = props;
  const {
    register, formState: { errors }, getValues, setError, clearErrors,
  } = useFormContext();
  const value = useWatch({ name });
  const [isShownPassword, setIsShownPassword] = useState(false);
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'border-red-600 focus:border-red-600' : 'border-gray-200 focus:border-gray-200';

  const handleShownPassword = () => setIsShownPassword(!isShownPassword);

  const handleChange = (e) => {
    if (confirmPasswordName && validation) {
      if (getValues(confirmPasswordName) !== e.target.value) {
        return setError(name, { type: 'required', message: validation });
      }

      return clearErrors(name);
    };

    return onChange(e.target.value);
  };

  return (
    <div className={` ${marginBottomStyle[size]}`}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <span className={`text-${size}`}>{label}</span>
          {required && <span className="text-red-600">*</span>}
        </Grid>
        <Grid item xs={8}>
          <div className="relative">
            <input
              {...register(name, {
                required,
                onChange: handleChange,
                maxLength,
                minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
              })}
              {...rest}
              className={`
              p-1 border text-${size} w-full focus:ring-0 placeholder:text-gray-300
              ${heightStyle[size]} ${disabledStyle} ${errorStyle}
            `}
              autoComplete="off"
              disabled={disabled}
              type={isShownPassword ? 'text' : 'password'}
              value={value || ''}
            />
            {!isShownPassword
              && (
                <EyeOffIcon
                  className={`absolute mr-[2%] top-1/2 right-0 transform -translate-y-1/2 ${iconSizeStyle[size]} text-gray-300 cursor-pointer`}
                  onClick={handleShownPassword}
                />
              )}
            {isShownPassword
              && (
                <EyeIcon
                  className={`absolute mr-[2%] top-1/2 right-0 transform -translate-y-1/2 ${iconSizeStyle[size]} text-gray-300 cursor-pointer`}
                  onClick={handleShownPassword}
                />
              )}
          </div>
        </Grid>
      </Grid>
      {confirmPasswordName && validation && errors[name] && <span className="flex justify-end text-red-600 text-xs mb-4">{validation}</span>}
    </div>
  );
}

Password.defaultProps = {
  confirmPasswordName: '',
  disabled: false,
  onChange: () => null,
  placeholder: 'Password...',
  required: false,
  size: 'xs',
  validation: undefined,
  maxLength: 1000,
  minLength: 0,
};

export default Password;

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { marginBottomSize } from 'utility/sizeStyle';

export interface CheckboxProps {
  disabled?: boolean;
  name: string;
  onChange?: (...args) => void;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline';
  spacing?: number;
  isControlled?: boolean;
  valueControlled?: boolean;
  visible?: boolean;
  readOnly?: boolean;
}

function Checkbox(props: CheckboxProps) {
  const {
    name, required, label, disabled, size, onChange, alignItems, spacing, isControlled, valueControlled, visible, readOnly, ...rest
  } = props;
  const { register, formState: { errors }, setValue } = useFormContext();
  const value = useWatch({ name });
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed border-gray-200 text-inherit cursor-not-allowed' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly
    ? 'text-gray-800 bg-gray-50 ' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  useEffect(() => {
    if (isControlled) {
      setValue(name, valueControlled);
    }
  }, [valueControlled]);

  if (!visible) return null;

  return (
    <Grid container spacing={spacing || 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      <Grid item xs="auto">
        <input
          {...register(name, {
            required,
            onChange,
          })}
          {...rest}
          className={`p-1 border border-gray-300 focus:outline-none focus:ring-0 ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}`}
          disabled={readOnly || disabled}
          type="checkbox"
          checked={isControlled ? valueControlled : value || false}
          id={name}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs>
        <span className={`text-${size}`}>
          {' '}
          <label htmlFor={name}>{label}</label>
          {' '}
        </span>
        {required && !disabled && <span className="text-red-600">*</span>}
      </Grid>
    </Grid>
  );
}

Checkbox.defaultProps = {
  disabled: false,
  onChange: () => null,
  required: false,
  size: 'xs',
  alignItems: 'center',
  spacing: null,
  isControlled: false,
  valueControlled: undefined,
  visible: true,
  readOnly: false,
};

export default Checkbox;

const normalizeCurrencyValue = (value) => {
  let parsed = value;
  parsed = parseInt(parsed, 10);
  if (Number.isNaN(parsed)) {
    parsed = value.toString().replace(/\D/g, '');
    parsed = parseInt(parsed, 10);
  }
  return parsed;
};

export const sanitizeCurrencyValue = (value) => {
  if (value) return parseInt(value.toString().replace(/\D/g, ''), 10);
  return 0;
};

const parseCurrency = (value) => {
  let parsed = value;

  try {
    parsed = normalizeCurrencyValue(value);
  } catch (e) {
    return '-';
  }
  if (Number.isNaN(parsed)) {
    return '-';
  }

  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const currency = formatter.format(parsed);
  return currency;
};

export default parseCurrency;

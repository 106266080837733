import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { SearchIcon } from '@heroicons/react/outline';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';

interface Options {
  name: string;
  value: string | number;
  key?: string;
}

interface AutoCompleteProps {
  disabled?: boolean;
  name: string;
  onInput?: (...args) => void;
  onChange?: (...args) => void;
  setIsSubmit?: (...args) => void;
  resetOnChange?: boolean;
  isSubmit?: boolean;
  placeholder?: string;
  required?: boolean;
  label?: string;
  options: Options[];
  icon?: React.ElementType;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
  readOnly?: boolean;
}

function AutoComplete(props: AutoCompleteProps) {
  const {
    name, required, label, disabled, options, placeholder, icon, wrap, onInput, onChange, resetOnChange, isSubmit, setIsSubmit, size, readOnly,
  } = props;
  const Icon = icon;
  const {
    register, formState: { errors }, setValue,
  } = useFormContext();
  const value = useWatch({ name });
  const inputRef = useRef(null);
  const disabledStyle = disabled ? 'bg-gray-50 text-inherit border-dashed cursor-not-allowed' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';
  const iconSize = {
    xs: 'h-3',
    sm: 'h-5',
    md: 'h-8',
    lg: 'h-12',
    xl: 'h-16',

  };
  const [textValue, setTextValue] = useState('');
  const [openOptions, setOpenOptions] = useState(false);
  const [optionList, setOptionList] = useState(options);
  const [activeOption, setActiveOption] = useState(0);

  const handleChange = (e) => {
    const textVal = e.target.value;
    setTextValue(textVal);
    const val = options.find((option) => option.name === textVal);
    if (!val) return setValue(name, '');
    return setValue(name, val.value);
  };

  const handleInput = (e) => {
    onInput(e.target.value);
    const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setOptionList(filteredOptions);
    setOpenOptions(true);
  };

  const updateValue = (selectedOption) => {
    setValue(name, selectedOption.value);
    setTextValue(selectedOption.name);
    onChange(selectedOption);
  };

  const handleSelectOption = (e) => {
    const selectedName = e.target.innerHTML;
    const selectedOption = options.find((option) => option.name === selectedName);
    updateValue(selectedOption);
    setOpenOptions(false);
  };

  const handlePressEnter = (e) => {
    e.preventDefault();
    const selectedOption = optionList.find((_, index) => index === activeOption);
    updateValue(selectedOption);
    setOpenOptions(false);
  };

  const handleKeyDown = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      handlePressEnter(e);
    }
    if (keyCode === 38) {
      if (activeOption === 0) return;
      setActiveOption((prevState) => prevState - 1);
      const selectedOption = optionList.find((_, index) => index === activeOption - 1);
      updateValue(selectedOption);
    }
    if (keyCode === 40) {
      if (activeOption + 1 === optionList.length) return;
      setActiveOption((prevState) => prevState + 1);
      const selectedOption = optionList.find((_, index) => index === activeOption + 1);
      updateValue(selectedOption);
    }
  };

  useEffect(() => {
    if (!value) return;
    const val = options.find((option) => option.value === value.toString());
    if (!val) return;
    setTextValue(val.name);
  }, [value]);

  useEffect(() => {
    setOptionList(options);
  }, [options]);

  useEffect(() => {
    if (get(errors, name)) inputRef.current.focus();
  }, [get(errors, name)]);

  useEffect(() => {
    if (isSubmit && resetOnChange) {
      updateValue({name: '', value: ''});
    }
    setIsSubmit(false);
  }, [isSubmit]);

  return (
    <Grid container spacing={wrap ? 2 : 1} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        <div className="relative">
          <div className="relative flex items-center justify-end">
            <input
              autoComplete="off"
              className={`p-1 border w-full focus:outline-none placeholder:text-gray-300 ${heightStyle[size]} 
              ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle} text-${size}`}
              disabled={disabled}
              placeholder={placeholder}
              onBlur={() => setOpenOptions(false)}
              onChange={handleChange}
              onFocus={handleInput}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              list={name}
              value={textValue}
              ref={inputRef}
            />
            <Icon className={`absolute ${iconSize[size]} text-gray-400 pr-2`} />
          </div>
          {openOptions && (
            <ul className="absolute bg-white w-full border-gray-200 border-t-0 border z-10 overflow-y-scroll max-h-44">
              {optionList.map((option, index) => (
                <li
                  key={option.value}
                  role="none"
                  className={`p-1 hover:bg-gray-200 hover:cursor-pointer text-${size} ${activeOption === index ? 'bg-gray-200' : ''}`}
                  onClick={handleSelectOption}
                  onMouseDown={handleSelectOption}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <input
          {...register(name, { required })}
          type="hidden"
          value={value || ''}
        />
      </Grid>
    </Grid>
  );
}

AutoComplete.defaultProps = {
  disabled: false,
  label: '',
  onInput: () => null,
  onChange: () => null,
  setIsSubmit: () => null,
  resetOnChange: false,
  isSubmit: false,
  placeholder: 'Search...',
  required: false,
  icon: SearchIcon,
  size: 'xs',
  wrap: true,
  readOnly: false,
};

export default AutoComplete;

import React from 'react';
import {
  Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme, IconButton,
} from '@mui/material';
import { Button } from 'components/basic';
import CloseIcon from '@mui/icons-material/Close';

type dialogProps = {
  title: string;
  confirmText?: string;
  cancelText?: string;
  content: React.ReactNode;
  action?: React.ReactNode;
  isLoading?: boolean;
  onConfirm?: (...args) => void;
  onClose: (...args) => void;
  open: boolean;
  showAction?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  showCloseIcon?: boolean;
}

function Dialog({
  title, onConfirm, content, open, onClose, action, isLoading, confirmText, cancelText, showAction, maxWidth, showCloseIcon,
}: dialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiDialog fullWidth onClose={!isLoading ? onClose : undefined} open={open} fullScreen={fullScreen} maxWidth={maxWidth}>
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          disabled={isLoading}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle>
        <span className="font-bold">
          {title}
        </span>
      </DialogTitle>
      <DialogContent className="p-2">
        {typeof content === 'string' && (
          <DialogContentText>
            {content}
          </DialogContentText>
        )}
        {typeof content !== 'string' && content}
      </DialogContent>
      {showAction && (
        <DialogActions>
          {action || (
            <div className="pr-2 flex gap-2">
              <Button color="red" onClick={onClose} value={cancelText} isLoading={isLoading} disabled={isLoading} />
              <Button onClick={onConfirm} value={confirmText} isLoading={isLoading} disabled={isLoading} />
            </div>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  action: undefined,
  confirmText: 'Ok',
  cancelText: 'Cancel',
  isLoading: false,
  onConfirm: () => null,
  showAction: true,
  maxWidth: 'sm',
  showCloseIcon: false,
};

export default Dialog;

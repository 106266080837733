import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormField from 'components/FormField';
import * as React from 'react';
import ErrorCaption from '../error-caption/ErrorCaption';

export interface textareaProps {
  label: string;
  name: string;
  onChange?: (...args) => void;
  value: number | string;
  disabled?: boolean;
  error?: string;
}

function Textarea(props: textareaProps) {
  const {
    onChange, value, label, name, disabled, error,
  } = props;
  const onInputChange = (val) => {
    onChange(val, name);
  };
  return (
    <>
      <FormField label={label}>
        <DatePicker
          onChange={onInputChange}
          value={value}
          disabled={disabled}
          InputProps={{
            style: {
              fontSize: 11,
            },
          }}
          renderInput={
            (params) => (
              <TextField
                id={name}
                name={name}
                className="w-full"
                size="small"
                {...params}
                sx={{
                  '& input': {
                    paddingLeft: 1,
                  },
                }}
              />
            )
          }
          inputFormat="DD-MM-yyyy"
        />
      </FormField>
      {error !== '' && (
        <ErrorCaption error={error} />
      )}
    </>
  );
}

Textarea.defaultProps = {
  disabled: false,
  onChange: () => null,
  error: '',
};

export default Textarea;

// This function is only for axios request
// This function will fire all Promises together

// All Promises are related (isRelated = true):
// If one promise fails, no data will be retrieved. Instead it will return an instanceof AggregateError.

// All Promises are unrelated (isRelated = false):
// If one promise fails, the other promises can still be retrieved.
// The failed promise will return an instanceof Error, while the succeeded promise will return your data.

async function handlePromises<T>(promises: Promise<T>[], isRelated = false): Promise<(T | Error)[]> {
  const results = await Promise.allSettled(promises);

  if (isRelated) {
    const isRejected = <U, >(p:PromiseSettledResult<U>): p is PromiseRejectedResult => p.status === 'rejected';
    const errors = results.filter(isRejected).map((result) => result.reason);
    if (errors.length) {
      // Aggregate all errors into one
      throw new AggregateError(errors);
    }
  }

  return results.map((result) => {
    if (result.status === 'fulfilled') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = result.value as any;
      return response;
    }
    return new Error(result.reason.message as string);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function handleMultiplePromises<T>(promises: Promise<T>[], isRelated = false): Promise<any[] | Error> {
  try {
    const results = await handlePromises(promises, isRelated);
    return results as T[];
  } catch (err) {
    if (err instanceof AggregateError) {
      const errorMessages = err.errors.map((error) => error.message);
      return new Error(`Errors occurred: ${errorMessages.join(', ')}`);
    }
    return err as Error;
  }
}

// eslint-disable-next-line import/prefer-default-export
export {handleMultiplePromises};

// const promise1:any = () => Promise.resolve(1);
// const promise2:any = () => Promise.reject(new Error('an error'));
// const promise3:any = () => Promise.resolve(3);
// const exampleRelatedPromises = async () => {
//   const results = await handleMultiplePromises([promise1(), promise2(), promise3()], true);

//   if (results instanceof Error) {
//     console.error(results);
//     console.info(results.message);
//   } else {
//     const [result1, result2, result3] = results;
//   }
// };
// const exampleUnrelatedPromises = async () => {
//   const results = await handleMultiplePromises([promise1(), promise2(), promise3()], false);

//   if (results instanceof Error) {
//     console.error(results);
//     console.info(results.message);
//   } else {
//     const [result1, result2, result3] = results;
//     if (result1 instanceof Error) {
//       console.error(result1);
//       console.info(result1.message);
//     } else {
//       console.log(result1);
//     }

//     if (result2 instanceof Error) {
//       console.error(result2);
//       console.info(result2.message);
//     } else {
//       console.log(result2);
//     }

//     if (result3 instanceof Error) {
//       console.error(result3);
//       console.info(result3.message);
//     } else {
//       console.log(result3);
//     }
//   }
// };

const parseAlphanum = (input): string => {
  try {
    const inputString = input.toString();

    if (typeof inputString === 'string') {
      return inputString.replace(/[^a-zA-Z0-9]/g, '');
    }
    return '';
  } catch (e) {
    return '';
  }
};

export default parseAlphanum;

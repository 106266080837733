import React, { createContext, useContext } from 'react';

export type UserContextType = {
  isAuthenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  permission: any[];
  setPermission: (permission: any) => void;
  role: {
    name: string;
    id: number;
  }
}

export const UserContext = createContext<UserContextType | null>(null);
export const useUserContext = () => useContext(UserContext);

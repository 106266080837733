import React, {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { Dialog } from 'components/mui';
import { Form, FormPassword } from 'components/react-hook-form';
import { SnackbarContext } from 'context/snackbar';
import { changePassword } from 'client/request/admin';
import { asyncWrapper } from 'utility/asyncWrapper';

interface IModalChangePassword {
  open: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

function ModalChangePassword({ open, setModalOpen }: IModalChangePassword) {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChangePassword = (val: string) => setFormValues({ password: val, confirmPassword: '' });

  const handleSubmitChangePassword = async (data) => {
    setLoading(true);
    const [res, err] = await asyncWrapper(changePassword(data));
    if (err) snackbar.show(err, 'error');
    if (res) {
      snackbar.show('Success changing password', 'success');
      setModalOpen(false);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      showAction={false}
      onClose={() => setModalOpen(false)}
      title="Change Password"
      content={(
        <Form initialValues={formValues} id="form-change-password" onSubmit={handleSubmitChangePassword} isLoading={loading}>
          <FormPassword
            label="New Password"
            name="password"
            onChange={handleChangePassword}
            required
            size="sm"
          />
          <FormPassword
            confirmPasswordName="password"
            label="Confirm Password"
            name="confirmPassword"
            required
            validation="Should match new password"
            size="sm"
          />
        </Form>
      )}
    />
  );
}

export default ModalChangePassword;

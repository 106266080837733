import React from 'react';
import {
  Controller, useFormContext,
} from 'react-hook-form';
import { Autocomplete, Grid } from '@mui/material';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import get from 'lodash/get';
import { SearchIcon } from '@heroicons/react/outline';

interface IAutoComplete {
  disabled?: boolean;
  name: string;
  required?: boolean;
  label?: string;
  labelOptName: string;
  valueOptName: string;
  // You can add more properties in Option object without changing the interface. Ex: {label: 'test1', value: 1, key: 'asd'}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  wrap?: boolean;
  spacing?: number
  onChange?: (...args) => void;
  onInputChange?: (...args) => void;
  isLoading?: boolean
  placeholder?: string
  readOnly?: boolean;
  filterOptions?: (opt: any[]) => any[];
}

const iconSize = {
  xs: 'h-3',
  sm: 'h-5',
  md: 'h-8',
  lg: 'h-12',
  xl: 'h-16',

};
function AutoCompleteControlled(props:IAutoComplete) {
  const {
    disabled, name, required, label, options, size, wrap, spacing, alignItems, onChange,
    labelOptName, valueOptName, onInputChange, isLoading, placeholder, readOnly, filterOptions,
  } = props;

  const { control, formState: { errors } } = useFormContext();

  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed text-inherit' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';
  const className = `
  p-1 border text-${size} border-gray-200 w-full focus:outline-none placeholder:text-gray-400 
  ${heightStyle[size]} ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}
`;

  const inputWidth = () => {
    if (!label) return 12;
    if (wrap) return 8;
    return 12;
  };

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      {label && (
        <Grid item xs={wrap ? 4 : 12}>
          <label htmlFor={name} className={`text-${size}`}>{label}</label>
          {required && !disabled && <span className="text-red-600">*</span>}
        </Grid>
      )}
      <Grid item xs={inputWidth()}>
        <Controller
          control={control}
          name={name}
          rules={{
            required: required ? `Field '${label}' is required` : false,
          }}
          render={({
            field: {
              ref, onChange: onChangeController, onBlur, value: valueController,
            },
          }) => (
            <Autocomplete
              readOnly={readOnly}
              onBlur={onBlur}
              ref={ref}
              disabled={disabled}
              loading={isLoading}
              filterOptions={filterOptions}
              options={options}
              getOptionLabel={(option) => option[labelOptName] || ''}
              isOptionEqualToValue={(opt, val) => opt[valueOptName] === val[valueOptName]}
              onChange={(_, data) => { onChangeController(data); onChange(data); }}
              onInputChange={onInputChange}
              value={valueController || null}
              fullWidth
              renderInput={(params) => (
                <div ref={params.InputProps.ref} className="relative">
                  <input
                    {...params.inputProps}
                    id={name}
                    className={className}
                    placeholder={placeholder}
                  />
                  <SearchIcon className={`absolute mr-[2%] top-1/2 right-0 transform -translate-y-1/2 ${iconSize[size]} text-gray-400 `} />
                </div>
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

AutoCompleteControlled.defaultProps = {
  disabled: false,
  label: '',
  required: false,
  size: 'xs',
  wrap: true,
  alignItems: 'center',
  spacing: null,
  onChange: () => null,
  onInputChange: () => null,
  isLoading: false,
  placeholder: '',
  readOnly: false,
  filterOptions: undefined,
};

export default AutoCompleteControlled;

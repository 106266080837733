import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import { Grid } from '@mui/material';
import { Button } from 'components/basic';
import isEmpty from 'lodash/isEmpty';

interface UploadProps {
  accept?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
}

function Upload(props: UploadProps) {
  const {
    accept, disabled, label, name, required, size, wrap, placeholder, ...rest
  } = props;
  const inputFileRef = useRef(null);
  const { register, formState: { errors }, setValue } = useFormContext();
  const [fileName, setFileName] = useState('');
  const value = useWatch({ name });
  const disabledButtonStyle = disabled ? 'border-dashed cursor-not-allowed hover:bg-gray-50' : 'bg-gray-500';
  const disabledContainerStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed hover:bg-gray-50' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  const onChangeHandler = (e) => {
    e.preventDefault();
    if (!e.target.files || (Array.isArray(e.target.files) && e.target.files.length <= 0)) return;
    let selectedFileName = e.target.files[0]?.name;
    if (selectedFileName && selectedFileName.length >= 15) selectedFileName = `...${selectedFileName.slice(-12)}`;
    setFileName(selectedFileName);
    setValue(name, e.target.files[0]);
  };

  const handleClick = () => inputFileRef.current.click();

  useEffect(() => {
    if (isEmpty(value)) return;
    setFileName(value);
  }, [value]);

  return (
    <Grid container spacing={2} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        <div
          className={`flex items-center gap-2 border border-gray-300 w-full ${heightStyle[size]} ${disabledContainerStyle} ${errorStyle}`}
          onClick={handleClick}
          role="button"
        >
          <Button
            disabled={disabled}
            className={`block text-white rounded-r w-14  text-center text-${size} ${heightStyle[size]} ${disabledButtonStyle}`}
            value="Browse"
          />
          <span className={`${fileName ? 'text-ellipsis overflow-hidden' : 'text-gray'} text-${size}`}>{fileName || placeholder}</span>
          <input
            {...register(name, {required})}
            {...rest}
            accept={accept}
            hidden
            ref={inputFileRef}
            autoComplete="off"
            type="file"
            disabled={disabled}
            onChange={onChangeHandler}
            className={`text-${size}`}
          />
        </div>
      </Grid>
    </Grid>
  );
}

Upload.defaultProps = {
  accept: 'application/pdf',
  disabled: false,
  placeholder: 'Select file..',
  required: false,
  size: 'xs',
  wrap: true,
};

export default Upload;

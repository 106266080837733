import React from 'react';

interface ISubsectionProps {
  label: React.ReactNode;
  children: React.ReactNode;
  classNames?: string;
}

function Subsection({ label, children, classNames }: ISubsectionProps) {
  return (
    <div className={`mb-7 ${classNames}`}>
      <span className="block font-bold text-sm text-black underline">{label}</span>
      <div className="px-2 mt-4">
        {children}
      </div>
    </div>
  );
}

Subsection.defaultProps = {
  classNames: '',
};

export default Subsection;

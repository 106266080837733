import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

type NavDropdownProps = {
  name: string;
  items: Array<{
    name?: string;
    href?: string;
    icon?: React.ElementType;
    isDivider?: boolean;
  }>;
  onClickRoute: (...args) => void;
}

export default function NavDropdown({name, items, onClickRoute}: NavDropdownProps) {
  return (
    <Popover className="relative">
      <>
        <Popover.Button
          className="px-2 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-500 flex"
        >
          <span>{name}</span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80"
            aria-hidden="true"
          />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute left-1/2 z-30 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0">
            {({ close }) => (
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white p-7 grid-cols-1">
                  {items.map((item) => {
                    if (item.isDivider) {
                      return (<hr />);
                    }
                    return (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => onClickRoute(e, item.href, close)}
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out
                          hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        {item.icon && (
                          <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                            <item.icon aria-hidden="true" />
                          </div>
                        )}
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}

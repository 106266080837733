import React from 'react';
import { classNames } from 'utility/classNames';

interface IMenu {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface ITabGroupProps {
  activeMenu: IMenu;
  label: string;
  menu: IMenu[];
  onSelectTab: (...args) => void;
}

function Tabgroup(props: ITabGroupProps) {
  const {
    onSelectTab, menu, activeMenu, label,
  } = props;
  const handleSelectTab = (e) => onSelectTab(e.target.value);
  return (
    <div className="ml-4 transition-all">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">{label}</label>
        <select
          id="tabs"
          className="bg-gray-50 border border-gray-300
       text-gray-900 text-sm rounded-lg focus:ring-blue-500
        focus:border-blue-500 block w-32 p-2.5"
          onChange={handleSelectTab}
          value={activeMenu.value}
        >
          {menu.map((m) => (
            <option key={m.value} value={m.value}>{m.label}</option>
          ))}
        </select>
      </div>
      <ul className="hidden sm:flex flex-wrap text-sm font-medium text-center text-gray-500 rounded-md divide-x-2 ">
        {menu.map((m) => (
          <li key={m.value}>
            <button
              disabled={m.value === activeMenu.value}
              type="button"
              onClick={handleSelectTab}
              className={classNames(
                activeMenu.value === m.value ? 'text-white bg-blue-600 cursor-pointer' : 'hover:text-gray-600 hover:bg-blue-50',
                'inline-block py-2 px-4',
              )}
              value={m.value}
            >
              {m.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tabgroup;

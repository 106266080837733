import React from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { marginBottomSize } from 'utility/sizeStyle';

interface TextAreaProps {
  disabled?: boolean;
  name: string;
  type?: 'email' | 'password' | 'text' | '';
  onChange?: (...args) => void;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  visible?: boolean;
  readOnly?: boolean;
}

function TextArea(props: TextAreaProps) {
  const {
    name, required, maxLength, minLength, label, disabled, size, onChange, visible, readOnly, ...rest
  } = props;
  const { register, formState: { errors } } = useFormContext();
  const value = useWatch({ name });
  const disabledStyle = disabled ? 'text-inherit bg-gray-50 border-dashed cursor-not-allowed' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  const handleChange = (e) => onChange(e, name);

  if (!visible) return null;

  return (
    <Grid container spacing={2} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs={4}>
        <span className={`text-${size}`}>{label}</span>
        {required && !disabled && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={8}>
        <textarea
          {...register(name, {
            required, maxLength, minLength, onChange: handleChange,
          })}
          {...rest}
          className={`p-1 text-${size}
           border border-gray-200 w-full min-h-7rem
            focus:border-gray-200 focus:ring-0 placeholder:text-gray-300 ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}`}
          disabled={disabled}
          value={value || ''}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
}

TextArea.defaultProps = {
  disabled: false,
  type: '',
  onChange: () => null,
  placeholder: '',
  required: false,
  maxLength: 524288,
  minLength: 0,
  size: 'xs',
  visible: true,
  readOnly: false,
};

export default TextArea;

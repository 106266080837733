// This function takes a string input, checks if it matches a decimal number pattern, returns the input as a string, and returns 0 if it doesn't.
export const normalizeDecimalValue = (value) => {
  const parsed = value;
  if (!parsed.match(/^[0-9]*\.?[0-9]*$/)) return NaN;
  return parsed;
};

const parseDecimal = (value) => {
  let parsed = value;
  try {
    parsed = normalizeDecimalValue(value);
  } catch (e) {
    return '0';
  }

  if (Number.isNaN(parsed)) {
    return '0';
  }

  return parsed.toString();
};

export default parseDecimal;

import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box, FormGroup, Grid,
} from '@mui/material';
import { marginBottomSize } from 'utility/sizeStyle';
import get from 'lodash/get';
import { FormCheckbox } from '..';

export interface ICheckboxOption{
  name: string
  label: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

interface ICheckboxGroup {
  disabled?: boolean;
  name: string;
  required?: boolean;
  parentLabel: string;
  parentChecked?: boolean
  options: ICheckboxOption[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  wrap?: boolean;
  spacing?: number
  onChange?: (...args) => void;
  onInputChange?: (...args) => void;
  isLoading?: boolean;
  isFocus?: boolean;
  readOnly?: boolean;
}
function CheckboxGroup(props:ICheckboxGroup) {
  const {
    disabled, name, required, options, size, wrap, spacing, alignItems, onChange, parentLabel, parentChecked, isFocus, readOnly,
  } = props;

  const {
    formState: { errors }, register, setValue, setFocus, watch, getValues,
  } = useFormContext();
  const checkboxRef = useRef<HTMLInputElement>();

  const disabledStyle = disabled ? 'bg-gray-50 border-dashed border-gray-200 text-inherit cursor-not-allowed' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly
    ? 'text-gray-800 bg-gray-50 ' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';
  const childNames = options.map((o) => (o.name));
  const childValues: boolean[] = watch(childNames);

  let indeterminate = false;
  let allChecked = true;
  childValues.forEach((v) => {
    if (!v) {
      allChecked = false;
    }
    if (v) {
      indeterminate = true;
    }
  });

  const inputWidth = () => {
    if (!parentLabel) return 12;
    if (wrap) return 8;
    return 12;
  };

  const [state, setState] = React.useState({ options, parentChecked });

  const handleParentChange = (event) => {
    childNames.forEach((n) => {
      setValue(n, event.target.checked);
    });
    const newOptions = state.options.map((o) => ({ ...o, checked: event.target.checked }));
    setState({ options: newOptions, parentChecked: event.target.checked });
    onChange(event, newOptions);
  };

  const handleChildChange = (event) => {
    const newOptions = state.options.map((o) => (o.name === event.target.name ? { ...o, checked: event.target.checked } : o));
    const newParentChecked = newOptions.every((o) => o.checked);
    setState({ options: newOptions, parentChecked: newParentChecked });
    onChange(event, newOptions);
  };

  useEffect(() => {
    const newParentChecked = options.every((o) => o.checked);
    setState({parentChecked: newParentChecked, options});

    options.forEach((o) => {
      if ((getValues(o.name) === undefined || getValues(o.name) === null) && (o.checked !== undefined && o.checked !== null)) {
        setValue(o.name, o.checked);
      }
    });
  }, [options]);

  useEffect(() => {
    if (isFocus)setFocus(name);
  }, [isFocus]);

  useEffect(() => {
    const checkbox = checkboxRef.current;
    if (!checkbox) return;

    if (allChecked) {
      checkbox.checked = true;
      checkbox.indeterminate = false;
      setValue(name, true);
    } else if (!allChecked && !indeterminate) {
      checkbox.checked = false;
      checkbox.indeterminate = false;
      setValue(name, false);
    } else if (indeterminate && !allChecked) {
      checkbox.checked = false;
      checkbox.indeterminate = true;
      setValue(name, true);
    }
  }, [indeterminate, allChecked]);

  if (options?.length < 1) return null;
  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      <Grid item xs={inputWidth()}>

        <FormGroup sx={{ margin: 0 }}>
          <Grid container spacing={spacing || 1}>
            <Grid item xs="auto">
              <input
                {...register(name, {
                  required, onChange: handleParentChange,
                })}
                className={`p-1 border border-gray-300 focus:outline-none focus:ring-0 ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}`}
                disabled={readOnly || disabled}
                type="checkbox"
                ref={checkboxRef}
                name={name}
                id={name}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs>
              <label htmlFor={name} className={`text-${size}`}>{parentLabel}</label>
              {required && !disabled && <span className="text-red-600">*</span>}
            </Grid>
          </Grid>

          <Box sx={{
            display: 'flex', flexDirection: 'column', ml: 3, mt: 1,
          }}
          >
            {options.map((option) => (
              <FormCheckbox
                required={option.required}
                key={option.name.toString()}
                label={option.label.toString()}
                name={option.name.toString()}
                onChange={handleChildChange}
                disabled={option.disabled}
                readOnly={option.readOnly}
              />
            ))}
          </Box>
        </FormGroup>

      </Grid>
    </Grid>
  );
}

CheckboxGroup.defaultProps = {
  disabled: false,
  required: false,
  size: 'xs',
  wrap: true,
  alignItems: 'center',
  spacing: null,
  onChange: () => null,
  onInputChange: () => null,
  isLoading: false,
  parentChecked: false,
  isFocus: false,
  readOnly: false,
};

export default CheckboxGroup;

import React from 'react';
import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import DxTextEditor from 'components/dx/texteditor/TextEditor';

interface TextEditorProps {
  disabled?: boolean;
  height?: string | number;
  name: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
}

function TextEditor(props: TextEditorProps) {
  const {
    name, required, label, disabled, size, wrap, height,
  } = props;
  const { formState: { errors }, control } = useFormContext();
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  return (
    <Grid container spacing={wrap ? 2 : 1} alignItems="center" mb={2}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        <Controller
          control={control}
          name={name}
          render={({
            field: {
              onChange, onBlur, value, ref,
            },
          }) => (
            <DxTextEditor
              className={`${disabledStyle} ${errorStyle}`}
              disabled={disabled}
              height={height}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              refs={ref}
            />
          )}
          rules={{ required }}
        />
      </Grid>
    </Grid>
  );
}

TextEditor.defaultProps = {
  disabled: false,
  height: 300,
  required: false,
  size: 'sm',
  wrap: false,
};

export default TextEditor;

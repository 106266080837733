import React from 'react';

export interface errorProps {
  error: string;
}

function ErrorCaption({error}: errorProps) {
  return (
    <>
      <p style={{ marginTop: '-5px' }}> &nbsp; </p>
      <p className="text-xs" style={{ color: 'red', marginTop: '-5px' }}>
        {error}
      </p>
    </>
  );
}

export default ErrorCaption;

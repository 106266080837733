import { AxiosError, AxiosResponse } from 'axios';
import { IResponseErrorV2 } from 'types/IResponseApi';

const asyncWrapper = async (promise): Promise<[AxiosResponse, string]> => {
  try {
    const res = await promise;
    return [res, undefined];
  } catch (e) {
    const { message: axiosMessage, response, request } = e as AxiosError;
    let errRes = response.data;

    if (
      request.responseType === 'blob'
      && response.data instanceof Blob
      && response.data.type
      && response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      errRes = JSON.parse(await response.data.text());
    }

    const { message, error }: Partial<IResponseErrorV2> = errRes;

    if (error) {
      console.error(error);
      return [undefined, error];
    }

    if (message) {
      console.error(message);
      return [undefined, message];
    }

    console.error(axiosMessage);
    return [undefined, axiosMessage];
  }
};

// eslint-disable-next-line import/prefer-default-export
export { asyncWrapper };

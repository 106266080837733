import React from 'react';
import { sizeStyle } from 'utility/sizeStyle';

const colorStyle = {
  white: 'text-white',
  red: 'text-red-600',
  blue: 'text-blue-600',
  green: 'text-green-600',
};

interface SpinnerProps {
  color?: 'white' | 'red' | 'blue' | 'green';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  flexGrow?: 1 | 0 | number;
};

function Spinner({ color, size, flexGrow }: SpinnerProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-5 w-5 flex-${flexGrow} animate-spin stroke-current stroke-[3] ${colorStyle[color]} ${sizeStyle[size]}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        className="stroke-current opacity-25"
      />
      <path d="M12 2C6.47715 2 2 6.47715 2 12C2 14.7255 3.09032 17.1962 4.85857 19" />
    </svg>
  );
}

Spinner.defaultProps = {
  color: 'white',
  size: 'xs',
  flexGrow: 1,
};

export default Spinner;

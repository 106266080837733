import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'authConfig';
import { MsalProvider } from '@azure/msal-react';
import Routes from './AllRoutes';
import reportWebVitals from './reportWebVitals';
import AppMeta from './components/GlobalMeta';
import 'devextreme/dist/css/dx.light.css';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <HelmetProvider>
        <AppMeta />
      </HelmetProvider>
      <Routes />
    </MsalProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <App />
    </LocalizationProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

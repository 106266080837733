// This function takes a string as input and extracts or pull out the numeric characters from it and return a string of only numeric characters.
const normalizeStringValue = (value) => {
  let parsed = value;
  parsed = value.toString().replace(/\D/g, '');

  return parsed;
};

const parseNumeric = (value): string => {
  let parsed = value;
  try {
    parsed = normalizeStringValue(value);
  } catch (e) {
    return '';
  }

  return parsed.toString();
};

export default parseNumeric;

import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';

interface IButtonProps {
  className?: string;
  color?: 'white' | 'red' | 'blue' | 'green';
  disabled?: boolean;
  form?: string;
  isLoading?: boolean;
  onClick?: (...args) => void;
  onKeyDown?: (...args) => void;
  type?: 'submit' | 'button';
  visible?: boolean;
  name?: string;
  editIcon?: boolean;
}
function ButtonIcon(props: IButtonProps) {
  const {
    disabled, isLoading, onClick, onKeyDown, type, className, color, form, visible, name, editIcon,
  } = props;
  const colorStyle = {
    white: 'bg-white hover:bg-gray-200 text-black',
    red: 'bg-red-600 hover:bg-red-800 text-white',
    blue: 'bg-blue-600 hover:bg-blue-800 text-white',
    green: 'bg-green-600 hover:bg-green-800 text-white',
  };
  const disabledButtonStyle = disabled
    ? 'bg-gray-300 hover:bg-gray-300 drop-shadow-none hover:drop-shadow-none cursor-not-allowed'
    : `${colorStyle[color]} drop-shadow-md hover:drop-shadow-lg`;

  return (
    <>
      {null}
      {visible
    && (
      <button
        className={`${className} ${disabledButtonStyle}`}
        disabled={disabled}
        form={form}
        onClick={onClick}
        onKeyDown={onKeyDown}
        type={type === 'submit' ? 'submit' : 'button'}
        name={name}
      >
        <div className="flex justify-between items-center gap-2">
          <span className={isLoading ? '' : 'w-full'}>
            {editIcon === true && <EditIcon />}
          </span>
        </div>
      </button>
    )}
    </>
  );
}

ButtonIcon.defaultProps = {
  className: 'px-5 h-10 rounded-md',
  color: 'blue',
  disabled: false,
  form: undefined,
  isLoading: false,
  onClick: () => null,
  onKeyDown: () => null,
  type: 'button',
  visible: true,
  name: '',
  editIcon: false,
};

export default ButtonIcon;

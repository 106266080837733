import FormField from 'components/FormField';
import * as React from 'react';
import ErrorCaption from '../error-caption/ErrorCaption';

export interface textareaProps {
  disabled?: boolean;
  label: string;
  name: string;
  onChange?: (...args) => void;
  value: number | string;
  error?: string;
}

function Textarea(props: textareaProps) {
  const {
    onChange, value, label, name, disabled, error,
  } = props;
  const onInputChange = (e) => {
    const val = e.target.value;
    onChange(val, name);
  };
  return (
    <>
      <FormField label={label}>
        <textarea
          disabled={disabled}
          id={name}
          name={name}
          className="p-1 border border-gray-200 w-full focus:outline-none text-xs"
          value={value}
          onChange={onInputChange}
        />
      </FormField>
      {error !== '' && (
        <ErrorCaption error={error} />
      )}
    </>
  );
}

Textarea.defaultProps = {
  disabled: false,
  onChange: () => null,
  error: '',
};

export default Textarea;

import FormField from 'components/FormField';
import * as React from 'react';
import ErrorCaption from '../error-caption/ErrorCaption';

export interface inputProps {
  disabled?: boolean;
  label: string;
  name: string;
  onChange?: (...args) => void;
  type?: string;
  value: number | string;
  maxLength?: number;
  error?: string;
}

function Input(props: inputProps) {
  const {
    onChange, value, label, name, type, disabled, maxLength, error,
  } = props;
  const onInputChange = (e) => {
    let val = e.target.value;
    if (type === 'currency' || type === 'number' || type === 'percent') {
      val = e.target.value.replace(/\D/g, '');
    }
    onChange(val, name);
  };
  return (
    <>
      <FormField label={label}>
        <input
          disabled={disabled}
          id={name}
          name={name}
          className="p-1 border w-full focus:outline-none"
          value={value}
          onChange={onInputChange}
          maxLength={maxLength}
          style={{
            borderColor: error !== '' && 'red',
          }}
        />
      </FormField>
      {error !== '' && (
        <ErrorCaption error={error} />
      )}
    </>
  );
}

Input.defaultProps = {
  disabled: false,
  type: 'text',
  onChange: () => null,
  maxLength: 524288,
  error: '',
};

export default Input;

import React from 'react';
import {
  useFormContext,
} from 'react-hook-form';
import {
  Box, FormGroup, Grid,
} from '@mui/material';
import { marginBottomSize } from 'utility/sizeStyle';
import { FormRadioButtonV2 } from '..';

interface IRadioButtonOption{
  value: string | number;
  label: string;
  id: string;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

interface IRadioButtonGroup {
  parentLabel?: string
  disabled?: boolean;
  name: string;
  required?: boolean;
  options: IRadioButtonOption[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  wrap?: boolean;
  spacing?: number
  onChange?: (...args) => void;
  visible?: boolean;
  showInlineError?: boolean;
}
function RadioButtonGroup(props:IRadioButtonGroup) {
  const {
    parentLabel, disabled, name, required, options, size, wrap, spacing, alignItems, onChange, visible, showInlineError,
  } = props;

  const { formState: { errors }} = useFormContext();

  const inputWidth = () => {
    if (!parentLabel) return 12;
    if (wrap) return 8;
    return 12;
  };

  const errorMessage = () => {
    if (errors[name].type === 'required') {
      return `Field '${parentLabel}' is required`;
    }
    return errors[name]?.message?.toString();
  };

  if (!visible) return null;

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      {parentLabel && (
        <Grid item xs>
          <span className={`text-${size}`}>
            {' '}
            <label>{parentLabel}</label>
            {' '}
          </span>
          {required && <span className="text-red-600">*</span>}
        </Grid>
      )}
      <Grid item xs={inputWidth()}>
        <FormGroup sx={{ margin: 0 }}>
          <Box sx={{
            display: 'flex', mt: 1,
          }}
          >
            {options.map((option) => (
              <FormRadioButtonV2
                key={option.id.toString()}
                id={option.id}
                label={option.label}
                name={name}
                value={option.value}
                required={required}
                onChange={onChange}
                disabled={disabled ?? option.disabled}
                visible={visible}
                size={size ?? option.size}
              />
            ))}
          </Box>
        </FormGroup>
      </Grid>
      {showInlineError && errors[name] && (
        <Grid item xs={12}>
          <span className={`flex justify-end text-red-600 text-${size} mb-${marginBottomSize[size]}`}>{errorMessage()}</span>
        </Grid>
      )}
    </Grid>
  );
}

RadioButtonGroup.defaultProps = {
  parentLabel: undefined,
  disabled: undefined,
  required: false,
  size: undefined,
  wrap: true,
  alignItems: 'center',
  spacing: null,
  onChange: () => null,
  visible: true,
  showInlineError: false,
};

export default RadioButtonGroup;

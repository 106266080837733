import { getPermission } from 'client/request/admin';
import { apiV1, apiV2 } from 'client/request/axiosClient';
import { UserContext } from 'context/user';
import { config } from 'process';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from 'utility/hooks/useQuery';

export default function LoginV1() {
  const query = useQuery();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const processToken = () => {
    const authToken = query.get('tokenId');
    if (authToken) {
      localStorage.clear();
      localStorage.setItem('authToken', authToken);
      userContext.setAuthenticated(true);
      getPermission().then((res) => {
        localStorage.setItem('permissions', JSON.stringify(res.data.payload.permission));
        const emailUser = res.data.payload.user.email;
        localStorage.setItem('emailUser', emailUser);
      });
    }
  };

  useEffect(() => {
    processToken();
    return navigate('/');
  }, []);

  return (
    <div>
      <h2>Please Wait...</h2>
    </div>
  );
}

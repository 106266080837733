import React, { useRef } from 'react';
import { Controller, useFormContext} from 'react-hook-form';
import get from 'lodash/get';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import { Grid } from '@mui/material';
import { classNames } from 'utility/classNames';

interface UploadProps {
  accept?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
}

function Upload(props: UploadProps) {
  const {
    accept, disabled, label, name, required, size, wrap, placeholder, ...rest
  } = props;
  const inputFileRef = useRef(null);
  const { formState: { errors }, control} = useFormContext();
  const disabledButtonStyle = disabled ? 'bg-gray-300 border-dashed cursor-not-allowed' : 'bg-gray-500 group-hover:bg-gray-600';
  const disabledContainerStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed hover:bg-gray-50' : 'hover:border-gray-500';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  const handleClick = () => inputFileRef.current.click();

  return (
    <Grid container spacing={2} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        <Controller
          control={control}
          name={name}
          rules={{
            validate: (v) => {
              if (required) {
                return !!v;
              } return true;
            },
          }}
          render={({field: {onChange, value}}) => (
            <div
              className={`group flex items-center gap-2 border border-gray-300 w-full ${heightStyle[size]} ${disabledContainerStyle} ${errorStyle}`}
              onClick={handleClick}
              role="button"
            >
              <button
                type="button"
                disabled={disabled}
                className={`block text-white rounded-r w-14 text-center text-${size} ${heightStyle[size]} ${disabledButtonStyle}`}
              >
                Browse
              </button>
              <input
                readOnly
                className={classNames(`${heightStyle[size]}`, `${value?.name ? 'whitespace-nowrap'
                  : 'text-gray'} text-${size}`, disabled ? 'cursor-not-allowed' : 'cursor-pointer', 'w-full bg-transparent focus:outline-none ')}
                value={value?.name || placeholder}
              />
              <input
                {...rest}
                accept={accept}
                hidden
                ref={inputFileRef}
                autoComplete="off"
                type="file"
                multiple={false}
                disabled={disabled}
                onChange={(event) => {
                  const fileEvent = event.target.files[0];
                  onChange(fileEvent);
                }}
                className={`text-${size}`}
              />
            </div>
          )}
        />
      </Grid>
    </Grid>
  );
}

Upload.defaultProps = {
  accept: 'application/pdf',
  disabled: false,
  placeholder: 'Select file..',
  required: false,
  size: 'xs',
  wrap: true,
};

export default Upload;

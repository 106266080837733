import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import ImageFallback from '../../ImageFallback';

interface InputUploadProps {
  disabled?: boolean;
  name: string;
  type?: 'email' | 'password' | 'text' | 'number' | 'currency' | 'percent' | 'file';
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  label: string;
  wrap?: boolean;
  fileType?: string;
  editMode?: boolean;
  srcUrl?: {
    docPath: string;
  };
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

function InputUpload(props: InputUploadProps) {
  const {
    name, type, required, maxLength, minLength, label, disabled, wrap, fileType, editMode, srcUrl, size, ...rest
  } = props;
  const { register, formState: { errors } } = useFormContext();
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';
  const [valueChanged, setValueChanged] = useState(false);

  return (
    <Grid container spacing={2} alignItems="center" mb={2}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        {type === 'file' && editMode === false && (
          <input
            {...register(name, {
              required, maxLength, minLength,
            })}
            {...rest}
            autoComplete="off"
            type={type}
            accept={fileType}
            multiple={false}
            className={`${errorStyle}`}
            disabled={disabled}
          />
        )}
        {type === 'file' && editMode === true && (
          <div className="flex flex-wrap gap-5 items-end">
            <div className="flex flex-col items-center">
              <input
                {...register(name, {
                  required, maxLength, minLength,
                })}
                {...rest}
                autoComplete="off"
                type={type}
                accept={fileType}
                multiple={false}
                className={`${errorStyle}`}
                disabled={disabled}
                onChange={() => setValueChanged(true)}
              />
            </div>
            <div className="flex flex-col items-center">
              {valueChanged === false && (
                <Link className="flex items-center justify-center h-64 w-52 border border-primary" href={srcUrl.docPath} target="_blank">
                  <ImageFallback imageUrl={srcUrl.docPath} />
                </Link>
              )}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

InputUpload.defaultProps = {
  disabled: false,
  type: 'text',
  placeholder: '',
  required: false,
  maxLength: 524288,
  minLength: 0,
  wrap: true,
  fileType: '',
  editMode: false,
  size: 'sm',
  srcUrl: {},
};

export default InputUpload;

import React from 'react';

type FormFieldProps = {
  label: JSX.Element | string;
  divider?: boolean;
  children: JSX.Element | string;
}

function FormField({label, children, divider}: FormFieldProps) {
  return (
    <>
      <div className="flex items-center text-xs">{label}</div>
      <div className="flex items-center text-xs">{children}</div>
    </>
  );
};

FormField.defaultProps = {
  divider: false,
};

export default FormField;

import React from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';

interface DatePickerProps {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
  readOnly?: boolean;
}

function DatePicker(props: DatePickerProps) {
  const {
    name, required, label, disabled, wrap, size, readOnly, ...rest
  } = props;
  const { register, formState: { errors } } = useFormContext();
  const value = useWatch({ name });
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed text-inherit disabled:text-inherit' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';
  const emptyValueStyle = !value ? 'text-gray' : '';

  return (
    <Grid container spacing={wrap ? 2 : 1} alignItems="center" mb={marginBottomSize[size]}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && !disabled && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={wrap ? 8 : 12}>
        <input
          {...register(name, {
            required,
          })}
          {...rest}
          className={`
            p-1 py-0 border text-${size} border-gray-200 w-full focus:border-gray-200 focus:ring-0
            ${emptyValueStyle} ${heightStyle[size]} ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}
          `}
          disabled={disabled}
          type="datetime-local"
          value={value || ''}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
}

DatePicker.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  size: 'xs',
  wrap: true,
  readOnly: false,
};

export default DatePicker;

import React, {
  Fragment, useEffect, useState,
} from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  UserIcon, MenuIcon, XIcon, ChevronDownIcon,
} from '@heroicons/react/outline';
import { useLocation, useNavigate } from 'react-router';
import {checkAccess, FORM, ACCESS} from 'utility/moduleAccess';
import { useUserContext } from 'context/user';
// import { useMsal } from '@azure/msal-react';
import { classNames } from 'utility/classNames';
import logoBprKirana from '../../images/logo-bpr-kirana.png';
import NavDropdown from './NavDropdown';
import ModalChangePassword from './ModalChangePassword';

const APP_VERSION = `Version ${process.env.REACT_APP_VERSION}`;

export default function NavBar() {
  const userContext = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.pathname.split('/')[1];
  const pathName = location.pathname;
  const [navigation, setNavigation] = useState([]);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  // const { instance } = useMsal();

  const populateMenu = async () => {
    const menu = [];
    menu.push({ name: 'Dashboard', href: '/' });
    const customerSubmenu = {
      name: 'Customer',
      isDropdown: true,
      items: [],
    };
    if (await checkAccess(userContext.permission, FORM.BORROWER, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Borrower', href: '/borrower' });
    }

    if (await checkAccess(userContext.permission, FORM.BORROWER, ACCESS.CREATE)) {
      customerSubmenu.items.push({ name: 'Add Borrower', href: '/borrower/create' });
    }

    if (await checkAccess(userContext.permission, FORM.BORROWER_GROUP, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Borrower Group', href: '/borrowergroup' });
    }

    if (await checkAccess(userContext.permission, FORM.LENDER, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Lender', href: '/lender' });
    }

    if (await checkAccess(userContext.permission, FORM.DANA_MENGENDAP, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Dana Mengendap', href: '/danamengendap' });
    }

    if (await checkAccess(userContext.permission, FORM.DAFTAR_AKUN_BANK, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'List Akun Bank', href: '/akunbanklist' });
    }

    if (await checkAccess(userContext.permission, FORM.BUYER_PROFILE, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Profil Peminjam', href: '/buyerprofile' });
    }

    if (await checkAccess(userContext.permission, FORM.DISBURSEMENT_ORDER, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Disbursement Order', href: '/disbursement-order' });
    }

    if (await checkAccess(userContext.permission, FORM.AUTOLENDING, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Auto Lending', href: '/auto-lending' });
    }

    if (await checkAccess(userContext.permission, FORM.AUTOLENDING_LIST, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Otomatis Auto Lending', href: '/auto-lending-list' });
    }

    if (await checkAccess(userContext.permission, FORM.AO_BORROWER, ACCESS.READ)) {
      customerSubmenu.items.push({ name: 'Borrower | Account Officer', href: '/account-officer/borrower' });
    }

    if (await checkAccess(userContext.permission, FORM.AO_BORROWER, ACCESS.CREATE)) {
      customerSubmenu.items.push({ name: 'Add Borrower | Account Officer', href: '/account-officer/borrower/create' });
    }

    const projectSubmenu = {
      name: 'Projects',
      isDropdown: true,
      items: [],
    };

    if (await checkAccess(userContext.permission, FORM.INVOICE, ACCESS.READ)) {
      projectSubmenu.items.push({ name: 'Manajemen Faktur', href: '/manajemenfaktur' });
    }

    if (await checkAccess(userContext.permission, FORM.AO_LOAN, ACCESS.READ)) {
      projectSubmenu.items.push({ name: 'Pengajuan Project AO', href: '/pengajuan-project-ao' });
    }

    if (await checkAccess(userContext.permission, FORM.LOAN, ACCESS.READ)) {
      if (!['BD', 'BPR_PDA'].includes(userContext?.role?.name)) { projectSubmenu.items.push({ name: 'Project List', href: '/project' }); }
    }

    if (await checkAccess(userContext.permission, FORM.LOAN_BPR_PDA, ACCESS.READ)) {
      projectSubmenu.items.push({
        name: 'Project List For Kirana',
        href: '/projectkirana',
      });
    }

    if (await checkAccess(userContext.permission, FORM.AO_LOAN, ACCESS.READ)) {
      projectSubmenu.items.push({ name: 'Project List | Account Officer', href: '/account-officer/project' });
    }

    if (await checkAccess(userContext.permission, FORM.OFFERING_BLOCK_LIST, ACCESS.READ)) {
      projectSubmenu.items.push({ name: 'Offering Lender Blocklist', href: '/offeringlenderblocklist' });
    }

    const masterSubmenu = {
      name: 'Master',
      isDropdown: true,
      items: [],
    };

    if (await checkAccess(userContext.permission, FORM.PROMOSI, ACCESS.READ)) {
      masterSubmenu.items.push({ name: 'Promosi', href: '/promosi' });
    }

    const adminSubmenu = {
      name: 'Admin',
      isDropdown: true,
      items: [],
    };

    if (await checkAccess(userContext.permission, FORM.USER, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'User Roles', href: '/userroles' });
    }

    if (await checkAccess(userContext.permission, FORM.PARTNERS, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Partners', href: '/partners/index' });
    }

    if (await checkAccess(userContext.permission, FORM.DEBET_RDL, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Debet RDL', href: '/debetrdl' });
    }

    if (await checkAccess(userContext.permission, FORM.BUSINESS_DEVELOPMENT, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Master Account Officer', href: '/account-officer' });
    }

    if (await checkAccess(userContext.permission, FORM.USER, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Master Admin', href: '/masteradmin' });
    }

    if (await checkAccess(userContext.permission, FORM.USER, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Master Roles', href: '/masterroles' });
    }

    if (await checkAccess(userContext.permission, FORM.EMAIL_TEMPLATE, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Master Email', href: '/masteremail' });
    }

    if (await checkAccess(userContext.permission, FORM.USER, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Master Form', href: '/masterform' });
    }

    if (await checkAccess(userContext.permission, FORM.USER, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Form Roles', href: '/formroles' });
    }

    if (await checkAccess(userContext.permission, FORM.TERMS_AND_CONDITIONS, ACCESS.READ)) {
      adminSubmenu.items.push({ name: 'Terms and Conditions', href: '/terms-and-conditions' });
    }

    if (customerSubmenu.items.length > 0) {
      menu.push(customerSubmenu);
    }
    if (projectSubmenu.items.length > 0) {
      menu.push(projectSubmenu);
    }
    if (masterSubmenu.items.length > 0) {
      menu.push(masterSubmenu);
    }
    if (adminSubmenu.items.length > 0) {
      menu.push(adminSubmenu);
    }

    setNavigation(menu);
  };

  useEffect(() => {
    if (userContext.permission && userContext.permission.length > 0 && userContext.isAuthenticated) {
      populateMenu();
    } else {
      setNavigation([]);
    }
  }, [userContext.permission, userContext.isAuthenticated]);

  const handleLogout = () => {
    window.localStorage.clear();
    userContext.setAuthenticated(false);
    return navigate('login', { replace: true });
  };

  // to be used later
  // const handleLogoutOAuth365 = () => {
  //   window.localStorage.clear();
  //   userContext.setAuthenticated(false);
  //   instance.logoutPopup({
  //     postLogoutRedirectUri: '/',
  //     mainWindowRedirectUri: '/',
  //   });
  // };

  const handleClickRoute = (e, url, close = undefined) => {
    e.preventDefault();
    if (close) close();
    navigate(url);
  };

  const handleClickChangePassword = () => setOpenModalChangePassword(true);

  useEffect(() => {
    if (page !== 'login' && !userContext.isAuthenticated) {
      // handleLogout();
    }
  }, [pathName]);

  useEffect(() => {
    if (pathName === '/') return;
    if (Array.isArray(navigation) && navigation.length !== 0) {
      let allowedRouteAccess = false;
      navigation.map((nav) => {
        if (nav.items) {
          if (allowedRouteAccess) return null;
          allowedRouteAccess = !!nav.items.find((n) => n.href.split('/')[1] === pathName.split('/')[1]);
        }
        return null;
      });
      if (!allowedRouteAccess) navigate('/');
    }
  }, [navigation]);

  return (
    <>
      <Disclosure as="nav" className="bg-primary">
        {({ open }) => (
          <>
            <div className="max-w-full mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                {userContext.isAuthenticated && navigation.length > 0 && (
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      className="inline-flex items-center text-slate-300 justify-center p-2 rounded-md
                  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                )}
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <div className="relative w-32">
                      <img
                        src={logoBprKirana}
                        alt="BPR-Kirana-Logo"
                        className="object-contain"
                      />
                    </div>
                  </div>
                  {userContext.isAuthenticated && navigation.length > 0 && (
                    <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4">
                        {
                          navigation.map((item) => {
                            if (item.isDropdown) {
                              return (
                                <div key={item.name}>
                                  <NavDropdown items={item.items} name={item.name} onClickRoute={handleClickRoute} />
                                </div>
                              );
                            }
                            return (
                              <a
                                key={item.name}
                                href={item.href}
                                onClick={(e) => handleClickRoute(e, item.href)}
                                className={classNames(
                                  'px-2 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-500',
                                )}
                              >
                                {item.name}
                              </a>
                            );
                          })
                        }
                      </div>
                    </div>
                  )}
                </div>
                {userContext.isAuthenticated
                && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <div>
                      <span className="text-white text-xs hidden lg:block">{APP_VERSION}</span>
                    </div>
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-offset-gray-800 focus:ring-white"
                        >
                          <UserIcon className="block h-8 w-8 p-2" aria-hidden="true" />
                          <span className="sr-only">Open user menu</span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right z-30 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1
                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <p className={classNames('block px-4 py-2 text-xs text-right text-gray-500 lg:hidden border-b')}>{APP_VERSION}</p>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={handleClickChangePassword}
                                onKeyDown={handleClickChangePassword}
                                role="button"
                                tabIndex={0}
                                className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700 border-b')}
                              >
                                Ubah Password
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={handleLogout}
                                onKeyDown={handleLogout}
                                role="button"
                                tabIndex={0}
                                className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                Sign out
                              </div>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>
            </div>

            {/* mobile */}
            <Disclosure.Panel className="sm:hidden">
              {navigation.length && (
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    item.isDropdown
                      ? (
                        <Disclosure key={item.name}>
                          {({ open: openMobile }) => (
                            <>
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="flex justify-between px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                              >
                                <span>{item.name}</span>
                                <ChevronDownIcon
                                  className={`${
                                    openMobile ? 'rotate-180 transform' : ''
                                  } h-5 w-5 text-white`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 py-2">
                                <ul>
                                  {item.items.map((i) => (
                                    <li>
                                      <a
                                        key={i.name}
                                        href={i.href}
                                        onClick={(e) => handleClickRoute(e, i.href)}
                                        className={classNames(
                                          'flex justify-between px-3 py-2.5 rounded-md text-base font-medium text-white hover:bg-gray-700',
                                        )}
                                      >
                                        {i.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ) : (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={(e) => handleClickRoute(e, item.href)}
                          className={classNames(
                            'flex justify-between px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700',
                          )}
                        >
                          {item.name}
                        </a>
                      )
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <ModalChangePassword open={openModalChangePassword} setModalOpen={setOpenModalChangePassword} />
    </>
  );
}

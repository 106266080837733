import React, { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';

export interface imageFallbackProps {
  imageUrl: string;
  openNewTabOnClick?: boolean;
}

function ImageFallback({ imageUrl, openNewTabOnClick }: imageFallbackProps) {
  const [isValid, setIsValid] = useState(true);

  if (!isValid) {
    return (
      <div>
        <a href={imageUrl} target="_blank" rel="noopener noreferrer" className="flex gap-2 items-center hover:brightness-75 underline transition-all">
          <DescriptionIcon className="text-xs" />
          <h6 className="truncate w-40">{imageUrl}</h6>
        </a>
      </div>
    );
  }

  if (openNewTabOnClick) {
    return (
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <img alt={imageUrl} className="object-cover h-full hover:brightness-90 transition-all" src={imageUrl} onError={() => setIsValid(false)} />
      </a>
    );
  }

  return <img alt={imageUrl} className="object-cover h-full" src={imageUrl} onError={() => setIsValid(false)} />;
}

ImageFallback.defaultProps = {
  openNewTabOnClick: false,
};

export default ImageFallback;
